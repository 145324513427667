import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
// @ukhc/devops-react-library
import Button from "@ukhc/devops-react-library/components/Button";
import TextArea from "@ukhc/devops-react-library/components/TextArea";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";

import { encodeBase64 } from "../utils/Helpers";

export default function CompletedComment({ ...props }) {
  const [httpResponseStatusCode, setHttpResponseStatusCode] = useState(null);

  function handleCloseModal() {
    // Close the modal
    document.getElementById("addCompletedCommentModalButton").click();
    props.onHide();
  }

  function handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    let fetchUrl = window.REACT_APP_API_URL + "/records/" + props.record.id;

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/completedComment",
          value: encodeBase64(event.target.completedComment.value),
        },
      ]),
    })
      .then((response) => {
        if (response.ok) {
          if (window.REACT_APP_DEBUG)
            console.log(
              "CompletedCommentModal handleSubmit response: " + response
            );
          return response.status;
        } else {
          setHttpResponseStatusCode(response.status);
          throw new Error(response.status);
        }
      })
      .then((result) => {
        return true;
      })
      .then(handleCloseModal)
      .catch((error) => {
        if (window.REACT_APP_DEBUG)
          console.log(
            "CompletedCommentModal handleSubmit error: " + JSON.stringify(error)
          );
        return false;
      });
  }

  return (
    <div
      className="modal fade"
      id="addCompletedCommentModal"
      role="dialog"
      aria-labelledby="addCompletedCommentModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addCompletedCommentModalLongTitle">
              <i className="bi bi-chat-dots text-primary mr-2"></i>
              Add comments
            </h5>
            <button
              type="button"
              id="addCompletedCommentModalButton"
              className="close"
              onClick={handleCloseModal}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h6 className="mb-3">
              Add comments for{" "}
              <strong>
                {props.record.employeeFirstName} {props.record.employeeLastName}
              </strong>
              .
            </h6>
            <form
              className="needs-validation"
              noValidate
              id="addCompletedCommentConfirmationForm"
              onSubmit={handleSubmit}
            >
              <TextArea
                required={false}
                defaultValue={props.record.completedComment}
                id="completedComment"
                label="Comments"
                rows={3}
                invalidFeedback="Please provide a reason for skipping employee signature."
              />
              <div className="modal-footer pb-0">
                <Button
                  variant="secondary"
                  isOutline={true}
                  text="Close"
                  onClick={handleCloseModal}
                />
                <Button
                  type="submit"
                  variant="primary"
                  id="addCommentsButton"
                  text="Add comments"
                />
              </div>
            </form>
            {httpResponseStatusCode > 399 && (
              <ResponseStatusAlert statusCode={httpResponseStatusCode} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
