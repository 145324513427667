import React from "react";

const ViewRecordCol = (props) => {
  const labelClass = "mb-0 d-flex align-items-center font-weight-bold";
  const labelStyle = {
    fontSize: 13,
    letterSpacing: 0.25,
  };
  let colsOverride = "";
  if (props.cols) {
    colsOverride = props.cols;
  }

  return (
    <div className={"col " + colsOverride}>
      <p className={labelClass} style={labelStyle}>
        {props.label}
      </p>
      <p style={{ wordWrap: "break-word" }}>
        <small>{props.value}</small>
      </p>
    </div>
  );
};

export default ViewRecordCol;
