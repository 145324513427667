// pdfMake
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { DateTime } = require("luxon");

const tempStatuses = [
  "Temp PT >.20",
  "Temp PT <.20",
  "Temp FT",
  "On Call",
  "Prem On Call",
];
const regStatuses = [
  "Reg FT",
  "Phased Retiree",
  "Reg PT >.74",
  "Reg PT-Faculty",
  "Reg PT .50-.74",
  "WEPP",
  "Reg PT .20-.49",
];
const fullTimeStatuses = ["Reg FT", "Temp FT"];
const partTimeStatuses = [
  "Temp PT >.20",
  "Temp PT <.20",
  "Phased Retiree",
  "Reg PT >.74",
  "Reg PT-Faculty",
  "Reg PT .50-.74",
  "On Call",
  "Prem On Call",
  "WEPP",
  "Reg PT .20-.49",
];

function formatSignatureDate(record) {
  if (
    !record.authorizedIndividualSignatureNotDesignated &&
    record.authorizedIndividualSignatureDate
  ) {
    return DateTime.fromISO(record.authorizedIndividualSignatureDate).toFormat(
      "D"
    );
  }
  if (
    !record.supervisorSignatureNotDesignated &&
    record.supervisorSignatureDate
  ) {
    return DateTime.fromISO(record.supervisorSignatureDate).toFormat("D");
  }

  if (!record.employeeSignatureNotDesignated && record.employeeSignatureDate) {
    return DateTime.fromISO(record.employeeSignatureDate).toFormat("D");
  }
  return "";
}

function compareReasonText(recordReason, reasonText) {
  return recordReason.toLowerCase() === reasonText.toLowerCase();
}

function formatSignature(signature, notDesignated) {
  if (notDesignated) {
    return "Not Designated";
  }

  return signature;
}

function formatEmployeeSignature(first, last, notDesignated, employeeSigned) {
  if (notDesignated) {
    return "Not Designated";
  }
  if (employeeSigned) {
    return `${first} ${last}`;
  }
  return "";
}

function formatPayRate(payRate) {
  let formattedPayRate = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "USD",
  }).format(payRate);
  console.log("formattedPayRate", formattedPayRate);
  return formattedPayRate;
}

function buildPDF(record) {
  return {
    styles: {
      header: {
        fontSize: 9,
        bold: true,
        alignment: "center",
      },
      sectionHeader: {
        fontSize: 5,
        bold: true,
      },
      label: {
        fontSize: 5,
        bold: true,
      },
      recordValue: {
        fontSize: 7,
      },
    },
    content: [
      {
        text: "University of Kentucky Employee Separation Sheet".toUpperCase(),
        style: "header",
      },
      {
        table: {
          widths: [27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27, 27],
          body: [
            // Row 1
            [
              {
                text: "SECTION A: EMPLOYEE/POSITION INFORMATION",
                colSpan: 14,
                style: "sectionHeader",
                fillColor: "#eeeeee",
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 2
            [
              {
                text: "Last Name",
                style: "label",
                colSpan: 3,
                border: [true, false, false, false], // [Left, Top, Right, Bottom]
              },
              {},
              {},
              {
                text: "First Name",
                style: "label",
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: "Middle",
                style: "label",
                colSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {
                text: "Person ID",
                style: "label",
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: "Faculty",
                style: "label",
                alignment: "center",
                border: [true, false, false, false],
              },
              {
                text: "Staff",
                style: "label",
                alignment: "center",
                border: [false, false, false, false],
              },
              {
                text: "Student",
                style: "label",
                alignment: "center",
                border: [false, false, true, false],
              },
            ],
            // Row 3
            [
              {
                text: record.employeeLastName,
                style: "recordValue",
                colSpan: 3,
                rowSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: record.employeeFirstName,
                style: "recordValue",
                colSpan: 3,
                rowSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {},
              {
                text: record.employeeMiddleInitial,
                style: "recordValue",
                colSpan: 2,
                rowSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {
                text: record.employeePersonId,
                style: "recordValue",
                colSpan: 3,
                border: [true, false, false, false],
              },
              {},
              {},
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: "X",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
            // Row 4
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: "Forwarding\nAddress",
                style: "label",
                rowSpan: 3,
                border: [true, true, false, false],
              },
              {
                text: record.forwardingAddress,
                style: "recordValue",
                colSpan: 5,
                rowSpan: 3,
                border: [false, true, true, false],
              },
              {},
              {},
              {},
              {},
            ],
            // Row 5
            [
              {
                text: "Org Unit",
                style: "label",
                border: [true, true, false, false],
              },
              {
                text: record.orgUnit,
                style: "recordValue",
                colSpan: 3,
                border: [false, true, false, false],
              },
              {},
              {},
              {
                text: "Position #",
                style: "label",
                colSpan: 2,
                border: [true, true, false, false],
              },
              {},
              {
                text: "Division",
                style: "label",
                colSpan: 2,
                border: [true, true, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 6
            [
              {
                text: "Dept. Name",
                style: "label",
                border: [true, false, false, false],
              },
              {
                text: record.deptName,
                style: "recordValue",
                colSpan: 3,
                border: [false, false, false, false],
              },
              {},
              {},
              {
                text: record.positionCode,
                style: "recordValue",
                colSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {
                text: record.division,
                style: "recordValue",
                colSpan: 2,
                border: [true, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 7
            [
              {
                text: "First Day\nWorked",
                style: "label",
                rowSpan: 2,
                border: [true, true, false, false],
              },
              {
                text: "Month",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Day",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Year",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "First Day Title",
                style: "label",
                colSpan: 4,
                border: [true, true, false, false],
                margin: [0, 3],
              },
              {},
              {},
              {},
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.separationFirstDayStatusTempRegular ===
                            "Temporary"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Temporary",
                style: "label",
                border: [true, true, true, false],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.firstDayStatusPTFT === "Part Time"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Part Time",
                style: "label",
                margin: [0, 3],
                border: [true, true, true, false],
              },
              {
                text: "First Day\nPay Rate",
                style: "label",
                rowSpan: 2,
                border: [false, true, false, false],
              },
              {
                text: record.firstDayPayRate
                  ? formatPayRate(record.firstDayPayRate)
                  : "$",
                style: "label",
                border: [false, true, true, false],
              },
            ],
            // Row 8
            [
              {},
              {
                text: DateTime.fromISO(record.firstDateWorked).toFormat("MM"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.firstDateWorked).toFormat("dd"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.firstDateWorked).toFormat("yyyy"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: record.separationFirstDayTitle,
                style: "recordValue",
                colSpan: 4,
                border: [true, false, false, false],
              },
              {},
              {},
              {},
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.separationFirstDayStatusTempRegular ===
                            "Regular"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Regular",
                style: "label",
                border: [true, false, true, true],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.firstDayStatusPTFT === "Full Time"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Full Time",
                style: "label",
                margin: [0, 3],
                border: [true, false, true, true],
              },

              {},
              {
                text: record.firstDayPayRatePer
                  ? `per ${record.firstDayPayRatePer}`
                  : "per",
                style: "label",
                border: [false, false, true, false],
              },
            ],
            // Row 9
            [
              {
                text: "Last Day\nWorked",
                style: "label",
                rowSpan: 2,
                border: [true, true, false, false],
              },
              {
                text: "Month",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Day",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Year",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Last Day Title",
                style: "label",
                colSpan: 4,
                border: [true, true, false, false],
                margin: [0, 3],
              },
              {},
              {},
              {},
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: tempStatuses.includes(record.lastDayStatusPTFT)
                            ? "X"
                            : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Temporary",
                style: "label",
                border: [true, true, true, false],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: partTimeStatuses.includes(
                            record.lastDayStatusPTFT
                          )
                            ? "X"
                            : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Part Time",
                style: "label",
                border: [true, true, true, false],
                margin: [0, 3],
              },

              {
                text: "Last Day\nPay Rate",
                style: "label",
                rowSpan: 2,
                border: [false, true, false, false],
              },
              {
                text: record.payrollAmount
                  ? formatPayRate(record.payrollAmount)
                  : "$",
                style: "label",
                border: [false, true, true, false],
              },
            ],
            // Row 10
            [
              {},
              {
                text: DateTime.fromISO(record.lastDateWorked).toFormat("MM"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.lastDateWorked).toFormat("dd"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.lastDateWorked).toFormat("yyyy"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: record.lastDayTitle,
                style: "recordValue",
                colSpan: 4,
                border: [true, false, false, false],
              },
              {},
              {},
              {},
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: regStatuses.includes(record.lastDayStatusPTFT)
                            ? "X"
                            : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Regular",
                style: "label",
                border: [true, false, true, true],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text: fullTimeStatuses.includes(
                            record.lastDayStatusPTFT
                          )
                            ? "X"
                            : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Full Time",
                style: "label",
                border: [true, false, true, true],
                margin: [0, 3],
              },
              {},
              {
                text: record.lastDayPayRatePer
                  ? `per ${record.lastDayPayRatePer}`
                  : "per",
                style: "label",
                border: [false, false, true, false],
              },
            ],
            // Row 11
            [
              {
                text: "Notice\nGiven",
                style: "label",
                rowSpan: 2,
                border: [true, true, false, false],
              },
              {
                text: "Month",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Day",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              {
                text: "Year",
                style: "label",
                alignment: "center",
                border: [false, true, false, false],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.separationNoticeGivenVia === "Written"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Written",
                style: "label",
                colSpan: 3,
                border: [false, true, true, false],
                margin: [0, 3],
              },
              {},
              {},
              {
                text: "Separation Date",
                style: "label",
                colSpan: 2,
                border: [true, true, true, false],
                margin: [0, 3],
              },
              {},
              {
                text: "Terminal Vacation Hours to be Paid",
                style: "label",
                colSpan: 4,
                border: [true, true, true, false],
                margin: [0, 3],
              },
              {},
              {},
              {},
            ],
            // Row 12
            [
              {},
              {
                text: DateTime.fromISO(record.dateNoticeGiven).toFormat("MM"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.dateNoticeGiven).toFormat("dd"),
                style: "recordValue",
                alignment: "center",
              },
              {
                text: DateTime.fromISO(record.dateNoticeGiven).toFormat("yyyy"),
                style: "recordValue",
                alignment: "center",
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.separationNoticeGivenVia === "Oral"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "Oral",
                style: "label",
                border: [false, false, false, true],
                margin: [0, 3],
              },
              [
                {
                  table: {
                    widths: [17],
                    body: [
                      [
                        {
                          text:
                            record.separationNoticeGivenVia === "None"
                              ? "X"
                              : " ",
                          style: "label",
                          border: [true, true, true, true],
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                },
              ],
              {
                text: "None",
                style: "label",
                border: [false, false, true, true],
                margin: [0, 3],
              },
              {
                text: DateTime.fromISO(record.separationDate).toFormat("D"),
                style: "recordValue",
                colSpan: 2,
                border: [true, false, true, false],
              },
              {},
              {
                text: record.terminalHrsToBePaid,
                style: "recordValue",
                colSpan: 4,
                border: [true, false, true, false],
              },
              {},
              {},
              {},
            ],
            // Row 13
            [
              {
                text: "SECTION B: REASON FOR SEPARATION (MARK ALL APPLICABLE)",
                colSpan: 14,
                style: "sectionHeader",
                fillColor: "#eeeeee",
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
          ],
        },
      },
      {
        table: {
          widths: [
            6,
            8,
            6,
            110,
            "auto",
            6,
            8,
            6,
            6,
            80,
            "auto",
            6,
            6,
            6,
            6,
            "auto",
          ],
          heights: [
            5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
            5, 5, 5, 5, 5, 5, 5, 5,
          ],
          body: [
            [
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
            ],
            // Row 1
            [
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Voluntary Quit (Eligible for Rehire)"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 3,
                rowSpan: 2,
                text: "VOLUNTARY QUIT\n\n(Eligible for Rehire)",
                border: [false, false, false, false],
                style: "label",
              },
              {},
              {},
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Lack of Work (Eligible for Rehire)"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 4,
                rowSpan: 2,
                text: "LACK OF WORK\n\n(Eligible for Rehire)",
                border: [false, false, false, false],
                style: "label",
              },
              {},
              {},
              {},
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "01",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Involuntary Discharge (Not Eligible for Rehire)"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 3,
                rowSpan: 2,
                text: "INVOLUNTARY DISCHARGE\n\n(Not Eligible for Rehire)",
                border: [false, false, false, false],
                style: "label",
              },
              {},
              {},
            ],
            // Row 2
            [
              { text: "", border: [false, false, false, false] },
              {},
              {},
              {},
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
            ],
            // Row 3
            [
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "05",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Self-Employment"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Self-Employment",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "16",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Funds Exhausted"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Funds Exhausted",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Insubordination"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Insubordination",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 4
            [
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "7A",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Conflict with Management"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Conflict with Management",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "17",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Position Eliminated"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Position Eliminated",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Violation of Univ. or Dept. Rule"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Violation of Univ. or Dept. Rule",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 5
            [
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "7B",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Conflict with Co-Workers"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Conflict with Co-Workers",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "18",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Reorganization"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Reorganization",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Falsification of Records"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Falsification of Records",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 6
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "7C",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Staffing/Workload"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Staffing/Workload",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "19",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Assignment Ended"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Assignment Ended",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Falsification of Records-Employment Application"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Employment Application",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 7
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "7D",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Lack of Respect"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Lack of Respect",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "20",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Seasonal Work"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Seasonal Work",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Falsification of Records-Time Records"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Time Records",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 8
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8A",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Employee Relocation"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Employee Relocation",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "--",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text:
                  record.separationReasons.some(
                    (reason) =>
                      reason.separationReasonText ===
                      "Other (Must Explain/Unknown)"
                  ) &&
                  record.primaryReasonValue ===
                    "Lack of Work (Eligible for Rehire)"
                    ? "X"
                    : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Other (Must Explain/Unknown)",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Falsification of Records-Other Records"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Other Records",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 9
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8B",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Spouse/Partner Relocation"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Spouse/Partner Relocation",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Defaming/Maligning Reputation of University"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Defaming/Maligning Reputation of University",
                border: [false, false, false, false],
                style: "label",
                noWrap: true,
              },
            ],
            // Row 10
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8C",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Travel/Agency Position (RNs only)"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Travel/Agency Position (RNs Only)",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "21",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Contract Not Renewed"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "CONTRACT NOT RENEWED",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Dishonesty")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Dishonesty",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 11
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8D",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Promotion/Advancement"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Promotion/Advancement",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Imperiling Safety of Others"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Imperiling Safety of Others",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 12
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8E",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Obtained Position Representing Different Job Experience"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                rowSpan: 2,
                text: "Obtained Position Representing\nDifferent Job Experience",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Retirement")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                rowSpan: 2,
                colSpan: 2,
                text: "RETIREMENT\nto be coded by Retirement",
                border: [false, false, false, false],
                style: "label",
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Gambling")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Gambling",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 13
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {},
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Negligent Destruction of University Property"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Negligent Destruction of University Property",
                border: [false, false, false, false],
                style: "label",
                noWrap: true,
              },
              {},
            ],
            // Row 14
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8F",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Desirable Work Schedule"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Desirable Work Schedule",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Substance Abuse-Drugs"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Substance Abuse: Drugs",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 15
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8G",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Desirable Commute"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Desirable Commute",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "22",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Deceased")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "DECEASED",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Substance Abuse-Alcohol"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Substance Abuse: Alcohol",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 16
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8H",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Compensation/Pay"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Compensation/Pay",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "Date:",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Violation of Local, State, or Federal Law"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Violation of Local, State, or Federal Law",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 17
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "8I",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Inability to Advance"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Inability to Advance",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Employee",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Incarceration"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Incarceration",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 18
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "09",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Domestic Responsibility"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Domestic Responsibility",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Retiree",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Physical or Mental Endangerment"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Physical or Mental Endangerment",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 19
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "10",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Health")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Health",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Fighting and/or Physical Assault"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Fighting and/or Physical Assault",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 20
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "11",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Failed to Return from Leave"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Failed to Return from Leave",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Voluntary Quit (Not Eligible for Rehire)"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 4,
                rowSpan: 2,
                text: "VOLUNTARY QUIT\n\n(Not Eligible for Rehire)",
                border: [false, false, false, false],
                style: "label",
              },
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Failure or Neglect to Perform Duties"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Failure or Neglect to Perform Duties",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 21
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "12",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "School Responsibility"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "School Responsibility",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Lack of Skills for the Job"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Lack of Skills for the Job",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 22
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "13",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Leaving School"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Leaving School",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "02",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Job Abandonment"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Job Abandonment",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Absence/Tardiness Problems"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Absence/Tardiness Problems:",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 23
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "14",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(reason.separationReasonText, "Graduated")
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Graduated",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "03",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Quit without Notice"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Quit without Notice",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Absence/Tardiness Problems-Excessive"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Excessive",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 24
            [
              { text: "", border: [false, false, false, false] },
              {
                text: "--",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text:
                  record.separationReasons.some(
                    (reason) =>
                      reason.separationReasonText ===
                      "Other (Must Explain/Unknown)"
                  ) &&
                  record.primaryReasonValue ===
                    "Voluntary Quit (Eligible for Rehire)"
                    ? "X"
                    : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Other (Must Explain/Unknown)",
                border: [false, false, false, false],
                style: "label",
              },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "15",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Anticipation of Discharge"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Anticipation of Discharge",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Absence/Tardiness Problems-Unauthorized"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Unauthorized",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 25
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: "27",
                border: [false, false, false, false],
                style: "label",
              },
              {
                text: record.separationReasons.some((reason) =>
                  compareReasonText(
                    reason.separationReasonText,
                    "Did Not Fulfill Notice"
                  )
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Did Not Fulfill Notice",
                border: [false, false, false, false],
                style: "label",
                colSpan: 2,
              },
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Absence/Tardiness Problems-Unreported"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Unreported",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 26
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Absence/Tardiness Problems-Improperly Reported"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                text: "Improperly Reported",
                border: [false, false, false, false],
                style: "label",
              },
            ],
            // Row 27
            [
              {
                text: "Explanation for Separation",
                border: [true, true, true, false],
                style: "label",
                colSpan: 12,
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Failure to Act in a Courteous or Cooperative Manner"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                rowSpan: 2,
                text: "Failure to Act in a Courteous\nor Cooperative Manner",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 28
            [
              {
                colSpan: 12,
                rowSpan: 4,
                text: record.separationReasonExplanation,
                border: [true, false, true, true],
                style: "recordValue",
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {},
              {},
            ],
            // Row 29
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: record.separationReasons.some(
                  (reason) =>
                    reason.separationReasonText ===
                    "Inappropriate or Unsuitable Job Performance"
                )
                  ? "X"
                  : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                rowSpan: 2,
                text: "Inappropriate or Unsuitable\nJob Performance",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            // Row 30
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              {},
              {},
            ],
            // Row 31
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text:
                  record.separationReasons.some(
                    (reason) =>
                      reason.separationReasonText ===
                      "Other (Must Explain/Unknown)"
                  ) &&
                  record.primaryReasonValue ===
                    "Involuntary Discharge (Not Eligible for Rehire)"
                    ? "X"
                    : "",
                style: "label",
                alignment: "center",
              },
              {
                colSpan: 2,
                text: "Other (Must Explain/Unknown)",
                border: [false, false, false, false],
                style: "label",
              },
              {},
            ],
            [
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
              { text: " ", border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        table: {
          // widths: [117, 117, 117, 117],
          widths: [39, 55, 72, 30, 30, 30, 30, 70, 68],
          body: [
            // Row 1
            [
              {
                text: "SECTION C: REVIEW BY HUMAN RESOURCES (Discharges Only)",
                colSpan: 9,
                style: "sectionHeader",
                fillColor: "#eeeeee",
                border: [true, true, true, true], // [Left, Top, Right, Bottom]
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 2
            [
              {
                text: "Discharge reviewed by _____________________________________________________________ in the Human Resources office of Employee Relations.",
                colSpan: 9,
                style: "label",
                margin: [0, 3],
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 3
            [
              {
                text: "SECTION D: EMPLOYEE'S COMMENTS (see instructions if more space is required)",
                colSpan: 9,
                style: "sectionHeader",
                fillColor: "#eeeeee",
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 4
            [
              {
                text: record.separationSectionDEmployeeComments
                  ? record.separationSectionDEmployeeComments.replace(
                      /\n/g,
                      " "
                    )
                  : " ",
                colSpan: 9,
                style: "recordValue",
                margin: [0, 3],
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 5
            [
              {
                text: "SECTION E: SIGNATURES",
                colSpan: 9,
                style: "sectionHeader",
                fillColor: "#eeeeee",
                border: [true, true, true, true],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 6
            [
              {
                text: formatSignature(
                  record.supervisorProxySignature,
                  record.supervisorSignatureNotDesignated
                ),
                style: "recordValue",
                alignment: "center",
                colSpan: 4,
                margin: [0, 1],
                border: [false, false, false, true],
              },
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: formatSignature(
                  record.authorizedIndividualNameFormatted,
                  record.authorizedIndividualSignatureNotDesignated
                ),
                style: "recordValue",
                alignment: "center",
                colSpan: 4,
                margin: [0, 1],
                border: [false, false, false, true],
              },
              {},
              {},
              {},
            ],
            // Row 7
            [
              {
                text: "Supervisor's Signature",
                style: "label",
                colSpan: 4,
                alignment: "center",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: "Signature of Official Authorized to Remove Employee from Payroll",
                style: "label",
                colSpan: 4,
                alignment: "center",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
            ],
            // Row 8
            [
              {
                text: record.skipEmployeeSignature
                  ? ""
                  : formatEmployeeSignature(
                      record.employeeFirstName,
                      record.employeeLastName,
                      record.employeeSignatureNotDesignated,
                      record.employeeSigned
                    ),
                style: "recordValue",
                alignment: "center",
                colSpan: 4,
                margin: [0, 1],
                border: [false, false, false, true],
              },
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: formatSignatureDate(record),
                style: "recordValue",
                alignment: "center",
                colSpan: 4,
                margin: [0, 1],
                border: [false, false, false, true],
              },
              {},
              {},
              {},
            ],
            // Row 9
            [
              {
                text: "Employee's Signature",
                style: "label",
                colSpan: 4,
                alignment: "center",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              { text: "", border: [false, false, false, false] },
              {
                text: "Date",
                style: "label",
                colSpan: 4,
                alignment: "center",
                border: [false, false, false, false],
              },
              {},
              {},
              {},
            ],
            // Row 10
            [
              {
                text: "SECTION F: REHIRE ELIGIBILITY",
                colSpan: 3,
                style: "sectionHeader",
                fillColor: "#eeeeee",
                border: [true, true, false, true],
              },
              {},
              {},
              {
                text: "**FOR HUMAN RESOURCES USE ONLY**",
                colSpan: 6,
                style: "sectionHeader",
                fillColor: "#eeeeee",
                border: [false, true, true, true],
              },
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 10a
            [
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
              { text: "", border: [false, false, false, false] },
            ],
            // Row 11
            [
              {
                text: "Is employee eligible for rehire at UK?",
                style: "label",
                colSpan: 3,
                border: [false, false, false, false],
                margin: [0, 1],
              },
              {},
              {},
              {
                text: " ",
                style: "label",
                alignment: "center",
              },
              {
                text: "Yes",
                style: "label",
                border: [false, false, false, false],
                margin: [0, 1],
              },
              {
                text: " ",
                style: "label",
                alignment: "center",
              },
              {
                text: "No",
                style: "label",
                border: [false, false, false, false],
                margin: [0, 1],
              },
              {
                text: " ",
                colSpan: 2,
                style: "recordValue",
                alignment: "center",
                border: [false, false, false, true],
              },
              {},
            ],
            // Row 12
            [
              { text: "", colSpan: 7, border: [false, false, false, false] },
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: "Signature",
                style: "label",
                alignment: "center",
                colSpan: 2,
                border: [false, false, false, false],
              },
              {},
            ],
            // Row 13
            [
              {
                text: "Comments:",
                style: "label",
                border: [false, false, false, false],
              },
              {
                text: " ",
                style: "label",
                colSpan: 8,
                border: [false, false, false, true],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            // Row 14
            [
              {
                text: "(See instructions if more space is needed)",
                style: "label",
                colSpan: 8,
                border: [false, false, false, false],
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: "REV 3/12",
                style: "label",
                alignment: "right",
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
  };
}

export { buildPDF };
