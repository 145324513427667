import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

export default class RemoveProxyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      isSubmitButtonEnabled: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseModal() {
    // Close the modal
    document.getElementById("closeRemoveProxyModalButton").click();
    this.props.onHide();
  }

  handleSubmit() {
    this.setState({
      isSubmitButtonEnabled: true,
    });

    let fetchUrl =
      window.REACT_APP_API_URL + "/records/" + this.props.record.id;

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/proxyName",
          value: "",
        },
        {
          op: "replace",
          path: "/proxyLinkBlueId",
          value: "",
        },
      ]),
    })
      .then((response) => {
        if (response.ok) {
          if (window.REACT_APP_DEBUG)
            console.log("RemoveProxyModal handleSubmit response: " + response);
          return response.status;
        } else {
          this.setState({ httpResponseStatusCode: response.status });
          throw new Error(response.status);
        }
      })
      .then((result) => {
        this.setState({
          isSubmitButtonEnabled: false,
        });
        return true;
      })
      .then(this.handleCloseModal)
      .catch((error) => {
        if (window.REACT_APP_DEBUG)
          console.log(
            "RemoveProxyModal handleSubmit error: " + JSON.stringify(error)
          );
        return false;
      });
  }

  render() {
    return (
      <div
        className="modal fade"
        id="removeProxyModal"
        role="dialog"
        aria-labelledby="removeProxyModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="removeProxyModalLongTitle">
                <i className="bi bi-person-x text-primary mr-2"></i>
                Remove proxy
              </h5>
              <button
                type="button"
                id="closeRemoveProxyModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.props.record.supervisorName &&
                this.props.record.supervisorLinkBlueId && (
                  <h5>
                    Removing the proxy for: {this.props.record.supervisorName}
                  </h5>
                )}
              {this.props.record.proxyLinkBlueId && (
                <h5 className="mt-3 text-danger">
                  {this.props.record.proxyName} is the current proxy for{" "}
                  {this.props.record.supervisorName}
                </h5>
              )}
              <hr />
              <button
                className="btn btn-danger font-weight-bold rounded-sm px-5 mr-2"
                id="removeButton"
                disabled={this.state.isSubmitButtonEnabled}
                onClick={this.handleSubmit}
                style={{
                  fontSize: 12,
                  letterSpacing: 0.25,
                }}
                type="button"
              >
                Remove Proxy
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
