import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
// Components
import AssignProxyLookup from "./AssignProxyLookup";

import { encodeBase64 } from "../utils/Helpers";

export default class AddProxyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCloseModal() {
    // Close the modal
    document.getElementById("closeAddProxyModalButton").click();
    this.props.onHide();
  }

  handleSubmit(sapName, sapLinkBlueId) {
    if (!sapName || !sapLinkBlueId) {
      if (window.REACT_APP_DEBUG)
        console.log("AddProxyModal: missing a value for submit.");
      return;
    }
    let fetchUrl =
      window.REACT_APP_API_URL + "/records/" + this.props.record.id;

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/proxyName",
          value: encodeBase64(sapName),
        },
        {
          op: "replace",
          path: "/proxyLinkBlueId",
          value: encodeBase64(sapLinkBlueId),
        },
      ]),
    })
      .then((response) => {
        if (response.ok) {
          if (window.REACT_APP_DEBUG)
            console.log("AddProxyModal handleSubmit response: " + response);
          return response.status;
        } else {
          this.setState({ httpResponseStatusCode: response.status });
          throw new Error(response.status);
        }
      })
      .then((result) => {
        return true;
      })
      .then(this.handleCloseModal)
      .catch((error) => {
        if (window.REACT_APP_DEBUG)
          console.log(
            "AddProxyModal handleSubmit error: " + JSON.stringify(error)
          );
        return false;
      });
  }

  render() {
    return (
      <div
        className="modal fade"
        id="addProxyModal"
        role="dialog"
        aria-labelledby="addProxyModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addProxyModalLongTitle">
                <i className="bi bi-person-plus text-primary mr-2"></i>
                Assign proxy
              </h5>
              <button
                type="button"
                id="closeAddProxyModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {(!this.props.record.supervisorName ||
                !this.props.record.supervisorLinkBlueId) && (
                <AssignProxyLookup
                  handleSubmit={this.handleSubmit}
                  user={this.props.user}
                />
              )}
              {this.props.record.supervisorName &&
                this.props.record.supervisorLinkBlueId && (
                  <h5>
                    Setting a proxy for: {this.props.record.supervisorName}
                  </h5>
                )}
              {this.props.record.proxyLinkBlueId && (
                <h5 className="mt-3 text-danger">
                  {this.props.record.proxyName} is the current proxy for{" "}
                  {this.props.record.supervisorName}
                </h5>
              )}
              {this.props.record.supervisorName &&
                this.props.record.supervisorLinkBlueId && (
                  <AssignProxyLookup
                    handleSubmit={this.handleSubmit}
                    user={this.props.user}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
