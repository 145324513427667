function Legend() {
  return (
    <div
      className="alert alert-primary py-1 px-2 d-none d-md-inline-block"
      role="alert"
    >
      <center>
        <span className="mr-2">
          <small>Legend</small>
        </span>
        <span className="badge badge-warning mr-2">Needs signature</span>
        <span className="badge badge-success mr-2">Ready for review</span>
        <span className="badge badge-light mr-2">Complete</span>
        <span className="badge badge-secondary mr-2">Canceled</span>
      </center>
    </div>
  );
}

export default Legend;
