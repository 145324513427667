const { DateTime } = require("luxon");

export default function EmployeeCommentsExportModal(props) {
  const exportToExcel = () => {
    var XLSX = require("xlsx");
    const filename = `${
      window.REACT_APP_NAME
    } ${DateTime.now().toISODate()}.xlsx`;

    // Describe the column headers
    const headers = [
      [
        "PR #",
        "Employee",
        "Employee ID",
        "Separation Date",
        "Separation Reason",
        "Last Day Title",
        "Department Name",
        "Department #",
        "Supervisor",
        "Explanation for Separation",
        "Employee Comments",
      ],
    ];
    const options = {
      origin: "A2",
      skipHeader: true,
      header: [
        "prNumber",
        "employeeNameFormatted",
        "employeePersonId",
        "separationDateFormatted",
        "separationReasonsFormatted",
        "lastDayTitle",
        "deptName",
        "orgUnit",
        "supervisorNameFormatted",
        "separationReasonExplanation",
        "separationSectionDEmployeeComments",
      ],
    };

    // Create a new workbook
    var workbook = XLSX.utils.book_new();
    // Convert the data to export to sheet format
    var ws = XLSX.utils.json_to_sheet(props.records, options);
    // Add column headers
    XLSX.utils.sheet_add_aoa(ws, headers);
    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, ws, "Employee Comments");
    // Write the workbook to an excel file to be downloaded
    XLSX.writeFileXLSX(workbook, filename);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    // Close the modal
    document.getElementById("closeCommentsExportModalButton").click();
    props.onHide();
  };

  return (
    <div
      className="modal fade"
      id="employeeCommentsExportModal"
      role="dialog"
      aria-labelledby="employeeCommentsExportModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="employeeCommentsExportModalTitle">
              Exporting {props.records.length} records
            </h5>
            <button
              id="closeCommentsExportModalButton"
              type="button"
              className="close"
              onClick={handleCloseModal}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary btn-sm"
                onClick={exportToExcel}
              >
                Download
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm ml-3"
                data-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
