const { DateTime } = require("luxon");

export default function RecordsExportModal(props) {
  const exportToExcel = () => {
    var XLSX = require("xlsx");
    const filename = `${
      window.REACT_APP_NAME
    } ${DateTime.now().toISODate()}.xlsx`;

    // Describe the column headers
    const headers = [
      [
        "PR #",
        "Employee",
        "Employee ID",
        "Employee linkblue",
        "Job Title",
        "Separation Date",
        "Employee Sub-Group",
        "Department Name",
        "Department #",
        "Supervisor",
        "Supervisor linkblue",
        "Proxy",
        "Proxy linkblue",
        "Separation Reason (Primary)",
        "Separation Reason (Secondary)",
        "Explanation for Separation",
        "Supervisor Signature",
        "Individual Authorized Signature",
        "Employee Signature",
      ],
    ];
    const options = {
      origin: "A2",
      skipHeader: true,
      header: [
        "prNumber",
        "employeeNameFormatted",
        "employeePersonId",
        "employeeLinkBlueId",
        "lastDayTitle",
        "separationDateFormatted",
        "employeeSubGroup",
        "deptName",
        "orgUnit",
        "supervisorNameFormatted",
        "supervisorLinkBlueId",
        "proxyName",
        "proxyLinkBlueId",
        "primaryReasonValue",
        "secondaryReasonValue",
        "separationReasonExplanation",
        "supervisorSignatureColumn",
        "individualAuthorizedSignatureColumn",
        "employeeSignatureColumn",
      ],
    };

    // Create a new workbook
    var workbook = XLSX.utils.book_new();
    // Convert the data to export to sheet format
    var ws = XLSX.utils.json_to_sheet(props.records, options);
    // Add column headers
    XLSX.utils.sheet_add_aoa(ws, headers);
    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, ws, "Employee Records");
    // Write the workbook to an excel file to be downloaded
    XLSX.writeFileXLSX(workbook, filename);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    // Close the modal
    document.getElementById("closeExportModalButton").click();
    props.onHide();
  };

  return (
    <div
      className="modal fade"
      id="recordsExportModal"
      role="dialog"
      aria-labelledby="recordsExportModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="recordsExportModalTitle">
              Exporting {props.records.length} records
            </h5>
            <button
              id="closeExportModalButton"
              type="button"
              className="close"
              onClick={handleCloseModal}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary btn-sm"
                onClick={exportToExcel}
              >
                Download
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm ml-3"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
