import React, { Component } from "react";
// @ukhc/devops-react-library
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";
// App components
import ViewRecord from "./ViewRecord";
import { PrimaryReasons, SecondaryReasons } from "./Reasons";

import { encodeBase64 } from "../utils/Helpers";

class EmployeeRecord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchResponseStatusCode: null,
      fetchResponseStatusMessage: null,
      employee: {},
      isLoading: false,
      formControls: { rows: [] },
      signingPhase: null,
      userRole: null,
      showSigningForm: false,
      primaryReasonValue: "",
      secondaryReasonValue: "",
      reasonsModified: false,
      separationReasonsTextLength: 0,
      employeeCommentsTextLength: 0,
    };
    this.handleChangeView = this.handleChangeView.bind(this);
    this.getEmployee = this.getEmployee.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleShowSigningForm = this.handleShowSigningForm.bind(this);
    this.getFormControls = this.getFormControls.bind(this);
    this.handlePrimaryReasonChange = this.handlePrimaryReasonChange.bind(this);
    this.handleSecondaryReasonChange =
      this.handleSecondaryReasonChange.bind(this);
    this.handleAddSeparationReasons =
      this.handleAddSeparationReasons.bind(this);
    this.handleDeleteSeparationReasons =
      this.handleDeleteSeparationReasons.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getSecondaryReasons = this.getSecondaryReasons.bind(this);
  }

  componentDidMount() {
    this.getEmployee();
  }

  handleOnChange(prop, event) {
    switch (prop) {
      case "separationSectionDEmployeeComments":
        this.setState({ employeeCommentsTextLength: event.length });
        break;
      case "separationReasonExplanation":
        this.setState({ separationReasonsTextLength: event.length });
        break;
      case "primaryReasonValue":
        this.handlePrimaryReasonChange(event);
        break;
      case "secondaryReasonValue":
        this.handleSecondaryReasonChange(event);
        break;
      default:
        break;
    }
  }

  getSecondaryReasons(primaryReasonValue) {
    const secondaryReasonOptions = [
      { value: "", label: "Select a secondary reason" },
    ];

    var filteredSecondaryReasons = [];
    if (primaryReasonValue) {
      var primaryReason = primaryReasonValue;
      // Filter the secondary reasons
      filteredSecondaryReasons = SecondaryReasons.filter((secondaryReason) => {
        return primaryReason === secondaryReason.primaryReason;
      });
    }

    filteredSecondaryReasons.forEach((reason) => {
      secondaryReasonOptions.push({ value: reason.label, label: reason.label });
    });

    return secondaryReasonOptions;
  }

  handlePrimaryReasonChange(newReason) {
    this.setState({
      primaryReasonValue: newReason,
      // secondaryReasonOptions: this.getSecondaryReasons(newReason),
      reasonsModified: true,
    });
  }

  handleSecondaryReasonChange(newReason) {
    this.setState({
      secondaryReasonValue: newReason,
      reasonsModified: true,
    });
  }

  handleAddSeparationReasons(reasonTitles) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleAddSeparationReasons called:", reasonTitles);
    }

    reasonTitles.forEach(async (title) => {
      const fetchUrl = window.REACT_APP_API_URL + "/separationReasons";

      const postObject = {
        recordId: this.state.employee.id,
        separationReasonText: title,
      };

      try {
        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
          },
          body: JSON.stringify(postObject),
        });
        const json = await response.json();
      } catch (error) {
        // TODO: Error handling
        if (window.REACT_APP_DEBUG) console.log(error);
      }
    });
  }

  handleDeleteSeparationReasons(reasonIds) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleDeleteSeparationReasons called:", reasonIds);
    }

    reasonIds.forEach((id) => {
      const fetchUrl = window.REACT_APP_API_URL + "/separationReasons/" + id;

      fetch(fetchUrl, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
        },
      })
        .then((response) => response.status)
        .then(
          (result) => {
            return true;
          },
          (error) => {
            console.error("Error during delete: " + error);
            return false;
          }
        );
    });
  }

  getFormControls(employee) {
    let formControls = {
      formDescription:
        "If any of the information is incorrect, or has changed since it was initiated, please DO NOT SIGN. Contact your supervisor.",
      rows: [
        {
          cols: [
            {
              label: "Employee/Position Information",
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "First Name",
                  property: "employeeFirstName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Name",
                  property: "employeeLastName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Person ID",
                  property: "employeePersonId",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Forwarding Address",
                  property: "forwardingAddress",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Org. Unit",
                  property: "orgUnit",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Dept. Name",
                  property: "deptName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Position #",
                  property: "positionCode",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Division",
                  property: "division",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "First Day Worked",
                  property: "firstDateWorked",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Title",
                  property: "separationFirstDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Status",
                  property: "separationFirstDayStatusTempRegular",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Temporary", label: "Temporary" },
                    { value: "Regular", label: "Regular" },
                  ],
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day PT/FT",
                  property: "firstDayStatusPTFT",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Part Time", label: "Part Time" },
                    { value: "Full Time", label: "Full Time" },
                  ],
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Last Day Worked",
                  property: "lastDateWorked",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Title",
                  property: "lastDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Status",
                  property: "lastDayStatusPTFT",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Reg FT", label: "Reg FT" }, // Regular | Full Time
                    { value: "Temp PT >.20", label: "Temp PT >.20" }, // Temp | Part Time
                    { value: "Temp PT <.20", label: "Temp PT <.20" }, // Temp | Part Time
                    { value: "Temp FT", label: "Temp FT" }, // Temp | Full Time
                    { value: "Non-Service", label: "Non-Service" }, // n/a | n/a
                    { value: "Phased Retiree", label: "Phased Retiree" }, // Regular | Part Time
                    { value: "Reg PT >.74", label: "Reg PT >.74" }, // Regular | Part Time
                    { value: "Reg PT-Faculty", label: "Reg PT-Faculty" }, // Regular | Part Time
                    { value: "Unpaid", label: "Unpaid" }, // n/a | n/a
                    { value: "Reg PT .50-.74", label: "Reg PT .50-.74" }, // Regular | Part Time
                    { value: "On Call", label: "On Call" }, // Temp | Part Time
                    { value: "Prem On Call", label: "Prem On Call" }, // Temp | Part Time
                    { value: "WEPP", label: "WEPP" }, // Regular | Part Time
                    { value: "Reg PT .20-.49", label: "Reg PT .20-.49" }, // Regular | Part Time
                  ],
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Notice Given",
                  property: "dateNoticeGiven",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Notice Type",
                  property: "separationNoticeGivenVia",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Written", label: "Written" },
                    { value: "Oral", label: "Oral" },
                    { value: "None", label: "None" },
                  ],
                },
              ],
            },
            {
              controls: [
                {
                  label: "Separation Date",
                  property: "separationDate",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              label: "Reason for Separation",
            },
          ],
        },
      ],
    };

    if (
      (employee.signingPhase === "supervisor" &&
        (this.props.user.isSupervisor || this.props.user.isProxy)) ||
      employee.authorizedIndividualCanEdit
    ) {
      // Set up primary reason select options
      const primaryReasonOptions = [
        { value: "", label: "Select a primary reason" },
      ];

      PrimaryReasons.forEach((reason) => {
        primaryReasonOptions.push({
          label: reason.label,
          value: reason.label,
        });
      });

      // Filter the secondary reason options by the selected primary reason
      const secondaryReasonOptions = this.getSecondaryReasons(
        this.state.primaryReasonValue
      );

      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                type: "select",
                label: "Primary Reason",
                options: primaryReasonOptions,
                property: "primaryReasonValue",
                // required: true,
                disabled: true,
              },
            ],
          },
          {
            controls: [
              {
                type: "select",
                label: "Secondary Reason",
                options: secondaryReasonOptions,
                property: "secondaryReasonValue",
                disabled: true,
                // required: true,
              },
            ],
          },
        ],
      });
    } else {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Separation Reasons",
                property: "separationReasonsFormatted",
                disabled: true,
                type: "textarea",
              },
            ],
          },
        ],
      });
    }

    let separationExplanationEnabled =
      (employee.signingPhase === "supervisor" &&
        (this.props.user.isSupervisor || this.props.user.isProxy)) ||
      employee.authorizedIndividualCanEdit;

    let employeeCommentsEnabled =
      employee.signingPhase === "employee" &&
      this.props.user.isUser &&
      this.props.user.linkblue === employee.employeeLinkBlueId;

    formControls.rows.push(
      {
        cols: [
          {
            controls: [
              {
                label: "Explanation for Separation",
                property: "separationReasonExplanation",
                disabled: !separationExplanationEnabled,
                type: "textarea",
                required:
                  this.state.secondaryReasonValue ===
                  "Other (Must Explain/Unknown)",
                maxLength: 450,
                invalidFeedback: "Comments must be less than 450 characters.",
                helpText: separationExplanationEnabled
                  ? `${
                      this.state.separationReasonsTextLength > 450
                        ? 0
                        : 450 - this.state.separationReasonsTextLength
                    } character${
                      450 - this.state.separationReasonsTextLength !== 1
                        ? "s"
                        : ""
                    } remaining.`
                  : "",
                showHelpButton: !separationExplanationEnabled,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Employee's Comments",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "Employee's Comments",
                property: "separationSectionDEmployeeComments",
                disabled: !employeeCommentsEnabled,
                type: "textarea",
                maxLength: 450,
                invalidFeedback: "Comments must be less than 450 characters.",
                helpText: employeeCommentsEnabled
                  ? `${
                      this.state.employeeCommentsTextLength > 450
                        ? 0
                        : 450 - this.state.employeeCommentsTextLength
                    } character${
                      450 - this.state.employeeCommentsTextLength !== 1
                        ? "s"
                        : ""
                    } remaining.`
                  : "",
                showHelpButton: !employeeCommentsEnabled,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Signatures",
            description: "Enter full name below and click Sign Form.",
          },
        ],
      }
    );

    if (
      employee.signingPhase === "supervisor" &&
      (this.props.user.isSupervisor || this.props.user.isProxy)
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Supervisor/Proxy",
                property: "supervisorProxySignature",
                disabled: false,
                required: true,
                type: "text",
              },
            ],
          },
          {
            controls: [],
          },
        ],
      });
    }

    if (
      employee.signingPhase === "authorizedIndividual" &&
      this.props.user.linkblue === employee.authorizedIndividualLinkBlueId
    ) {
      formControls.rows.push(
        {
          cols: [
            {
              controls: [
                {
                  label: "Supervisor",
                  property: "supervisorName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Supervisor Signature Date",
                  property: "supervisorSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label:
                    "Individual Authorized to Remove Employee From Payroll",
                  property: "authorizedIndividualSignature",
                  disabled: false,
                  required: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        }
      );
    }

    if (
      employee.signingPhase === "employee" &&
      this.props.user.linkblue === employee.employeeLinkBlueId
    ) {
      formControls.rows.push(
        {
          cols: [
            {
              controls: [
                {
                  label: "Supervisor",
                  property: "supervisorName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Supervisor Signature Date",
                  property: "supervisorSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label:
                    "Individual Authorized to Remove Employee From Payroll",
                  property: "authorizedIndividualName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label:
                    "Individual Authorized to Remove Employee From Payroll Signature Date",
                  property: "authorizedIndividualSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Employee",
                  property: "employeeSignature",
                  disabled: false,
                  required: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        }
      );
    }
    return formControls;
  }

  handleShowSigningForm(employee) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleShowSigningForm called:", employee);
    }

    const signingPhase = employee.signingPhase;
    const linkblue = this.props.user.linkblue;

    if (
      signingPhase === "employee" &&
      linkblue === employee.employeeLinkBlueId
    ) {
      return true;
    }

    if (
      signingPhase === "authorizedIndividual" &&
      linkblue === employee.authorizedIndividualLinkBlueId
    ) {
      return true;
    }

    if (
      signingPhase === "supervisor" &&
      (linkblue === employee.supervisorLinkBlueId ||
        linkblue === employee.proxyLinkBlueId)
    ) {
      return true;
    }
    return false;
  }

  getEmployee() {
    const url = new URL(window.REACT_APP_API_URL + "/records");
    url.searchParams.set("prNumber", this.props.pid);
    url.toString();

    this.setState({
      isLoading: true,
    });

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        this.setState({
          fetchResponseStatusCode: response.status,
        });
        throw new Error(response.status);
      })
      .then((data) => {
        const moment = require("moment");

        if (data.length === 0) {
          this.setState({
            employee: {},
            isLoading: false,
            fetchResponseStatusCode: 404,
            fetchResponseStatusMessage: "Employee record not found.",
          });
        } else {
          data.forEach((element) => {
            if (
              !(
                element.authorizedIndividualSignatureNotDesignated ||
                element.authorizedIndividualSigned
              ) ||
              !(
                element.supervisorSigned ||
                element.supervisorSignatureNotDesignated
              ) ||
              !(
                element.employeeSigned || element.employeeSignatureNotDesignated
              )
            ) {
              element.missingSignatures = "true";
            } else {
              element.missingSignatures = "false";
            }

            // Create formatted employee name property
            element.employeeNameFormatted =
              element.employeeFirstName + " " + element.employeeLastName;

            // Create formatted supervisor name property
            if (element.supervisorName) {
              element.supervisorNameFormatted =
                element.supervisorName.split(",")[1] +
                " " +
                element.supervisorName.split(",")[0];
            }

            // Create formatted supervisorSigned property
            if (element.supervisorSigned) {
              element.supervisorSignedFormatted = "Yes";
            } else {
              element.supervisorSignedFormatted =
                element.supervisorSignatureNotDesignated
                  ? "Not designated"
                  : "No";
            }

            // Create formatted supervisorSignatureDateFormatted property
            if (element.supervisorSigned) {
              element.supervisorSignatureDateFormatted = moment(
                element.supervisorSignatureDate
              ).format("L");
            } else {
              element.supervisorSignatureDateFormatted = "";
            }

            // Create formatted authorizedIndividualSigned property
            if (element.authorizedIndividualSigned) {
              element.authorizedIndividualSignedFormatted = "Yes";
            } else {
              element.authorizedIndividualSignedFormatted =
                element.authorizedIndividualSignatureNotDesignated
                  ? "Not designated"
                  : "No";
            }

            // Create formatted authorizedIndividualSignatureDateFormatted property
            if (element.authorizedIndividualSigned) {
              element.authorizedIndividualSignatureDateFormatted = moment(
                element.authorizedIndividualSignatureDate
              ).format("L");
            } else {
              element.authorizedIndividualSignatureDateFormatted = "";
            }

            // Create formatted employeeSigned property
            if (element.employeeSigned) {
              element.employeeSignedFormatted = "Yes";
            } else {
              element.employeeSignedFormatted =
                element.employeeSignatureNotDesignated
                  ? "Not designated"
                  : "No";
            }

            // Create formatted employeeSignatureDateFormatted property
            if (element.employeeSigned) {
              element.employeeSignatureDateFormatted = moment(
                element.employeeSignatureDate
              ).format("L");
            } else {
              element.employeeSignatureDateFormatted = "";
            }

            // Create a readyForReview property
            if (
              (element.supervisorSigned ||
                element.supervisorSignatureNotDesignated) &&
              (element.authorizedIndividualSigned ||
                element.authorizedIndividualSignatureNotDesignated) &&
              (element.employeeSigned ||
                element.employeeSignatureNotDesignated) &&
              !element.hrComplete
            ) {
              element.readyForReview = "true";
            } else {
              element.readyForReview = "false";
            }

            // Create formatted firstDateWorkedFormatted property
            if (element.firstDateWorked) {
              element.firstDateWorkedFormatted = moment(
                element.firstDateWorked
              ).format("L");
            }

            // Create formatted lastDateWorkedFormatted property
            if (element.lastDateWorked) {
              element.lastDateWorkedFormatted = moment(
                element.lastDateWorked
              ).format("L");
            }

            // Create formatted dateNoticeGivenFormatted property
            if (element.dateNoticeGiven) {
              element.dateNoticeGivenFormatted = moment(
                element.dateNoticeGiven
              ).format("L");
            }

            // Create formatted separationDateFormatted property
            if (element.separationDate) {
              element.separationDateFormatted = moment(
                element.separationDate
              ).format("L");
            }

            // Create a separationReasonsFormatted property
            if (element.separationReasons.length > 0) {
              element.separationReasonsFormatted = Array.prototype.map
                .call(element.separationReasons, (item) => {
                  return item.separationReasonText;
                })
                .join(", ");

              // Get primary reason value
              const primaryReasonArray = PrimaryReasons.filter(
                (primaryReason) => {
                  return element.separationReasons.some(
                    (propSeparationReason) => {
                      return (
                        primaryReason.label.toLowerCase() ===
                        propSeparationReason.separationReasonText.toLowerCase()
                      );
                    }
                  );
                }
              ).map((reason) => reason.label);
              // Grab initial primary reason
              element.primaryReasonValue = primaryReasonArray[0];

              // get the records secondary reason labels into an array
              const secondaryReasonArray = SecondaryReasons.filter(
                (secondaryReason) => {
                  return element.separationReasons.some(
                    (propSeparationReason) => {
                      return (
                        secondaryReason.label.toLowerCase() ===
                        propSeparationReason.separationReasonText.toLowerCase()
                      );
                    }
                  );
                }
              ).map((secondaryReason) => secondaryReason.label);
              // Grab initial secondary reason
              element.secondaryReasonValue = secondaryReasonArray[0];

              this.setState({
                primaryReasonValue: element.primaryReasonValue,
                secondaryReasonValue: element.secondaryReasonValue,
              });
            }

            // Set supervisor signing phase
            if (
              !element.supervisorSigned &&
              !element.supervisorSignatureNotDesignated
            ) {
              element.signingPhase = "supervisor";
              element.supervisorCanSign = true;
              element.authorizedIndividualCanSign = false;
              element.employeeCanSign = false;
            }

            // Set authorized individual signing phase
            if (
              (element.supervisorSigned ||
                element.supervisorSignatureNotDesignated) &&
              !element.authorizedIndividualSigned &&
              !element.authorizedIndividualSignatureNotDesignated
            ) {
              element.signingPhase = "authorizedIndividual";
              element.supervisorCanSign = false;
              element.authorizedIndividualCanSign = true;
              element.employeeCanSign = false;
              // Pass along if auth individual can edit when sup not designated
              element.authorizedIndividualCanEdit =
                element.authorizedIndividualLinkBlueId ===
                  this.props.user.linkblue &&
                element.supervisorSignatureNotDesignated;
            }

            // Set employee signing phase
            if (
              (element.supervisorSigned ||
                element.supervisorSignatureNotDesignated) &&
              (element.authorizedIndividualSigned ||
                element.authorizedIndividualSignatureNotDesignated) &&
              !element.employeeSigned &&
              !element.employeeSignatureNotDesignated
            ) {
              element.signingPhase = "employee";
              element.supervisorCanSign = false;
              element.authorizedIndividualCanSign = false;
              element.employeeCanSign = true;
            }

            // Set HR approval phase
            if (
              (element.supervisorSigned ||
                element.supervisorSignatureNotDesignated) &&
              (element.authorizedIndividualSigned ||
                element.authorizedIndividualSignatureNotDesignated) &&
              (element.employeeSigned || element.employeeSignatureNotDesignated)
            ) {
              element.signingPhase = "hr";
              element.supervisorCanSign = false;
              element.authorizedIndividualCanSign = false;
              element.employeeCanSign = false;
            }
          });
          const selectedEmployee = data[0];

          // Who can view the individual record?
          // - supervisor/proxy of employee
          // - authenticated user for employee
          // - employee

          const approvedViewers = [
            selectedEmployee.supervisorLinkBlueId,
            selectedEmployee.proxyLinkBlueId,
            selectedEmployee.authorizedIndividualLinkBlueId,
            selectedEmployee.employeeLinkBlueId,
          ];

          if (!approvedViewers.includes(this.props.user.linkblue)) {
            this.handleChangeView("Employee Records");
            return;
          }

          // if (window.REACT_APP_DEBUG) {
          //   console.log("current employee:", data[0]);
          // }

          this.setState({
            employee: selectedEmployee,
            // formControls: fullFormControls,
            isLoading: false,
            // showSigningForm: showSigningForm,
          });
        }
      })
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error.message && error.message === "Failed to fetch") {
          this.setState({
            fetchResponseStatusCode: 404,
          });
        }
      });
  }

  handleChangeView(view) {
    if (this.props.handleChangeView) {
      this.props.handleChangeView(view);
    }
  }

  handleOnSubmit(record) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleOnSubmit called:", record);
    }

    if (this.state.reasonsModified) {
      // Collect current ids in separationReasons array
      const idsToDeleteArray = [];
      this.state.employee.separationReasons.forEach((reason) => {
        idsToDeleteArray.push(reason.id);
      });

      const reasonsToAddArray = [
        this.state.primaryReasonValue,
        this.state.secondaryReasonValue,
      ];

      if (reasonsToAddArray.length > 0) {
        this.handleAddSeparationReasons(reasonsToAddArray);
      }

      if (idsToDeleteArray.length > 0) {
        this.handleDeleteSeparationReasons(idsToDeleteArray);
      }
    }

    record.preventDefault();

    const form = record.currentTarget;
    if (form.checkValidity() === false) {
      record.preventDefault();
      record.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    let moment = require("moment");

    const user = this.props.user;

    let patchJson = [];

    // If phase is employee and user is employee, patch employee
    if (
      this.state.employee.signingPhase === "employee" &&
      user.linkblue === this.state.employee.employeeLinkBlueId
    ) {
      patchJson.push(
        {
          op: "replace",
          path: "/employeeSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/employeeSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
      );

      // Employee Comments is updated
      if (
        String(record.target.separationSectionDEmployeeComments.value) !==
        String(this.state.employee.separationSectionDEmployeeComments)
      ) {
        patchJson.push({
          op: "replace",
          path: "/separationSectionDEmployeeComments",
          value: encodeBase64(
            record.target.separationSectionDEmployeeComments.value
          ),
        });
      }
    }

    // If phase is authorized individual and user is authorized individual, patch authorized individual
    if (
      this.state.employee.signingPhase === "authorizedIndividual" &&
      user.linkblue === this.state.employee.authorizedIndividualLinkBlueId
    ) {
      patchJson.push(
        {
          op: "replace",
          path: "/authorizedIndividualSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/authorizedIndividualSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
      );
    }

    // If phase is supervisor and user is supervisor, patch supervisor
    if (
      this.state.employee.signingPhase === "supervisor" &&
      (user.isSupervisor || user.isProxy)
    ) {
      let displayName = user.displayName;
      let formattedDisplayName = displayName.split(",");
      patchJson.push(
        {
          op: "replace",
          path: "/supervisorSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/supervisorSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          op: "replace",
          path: "/supervisorProxySignature",
          value: encodeBase64(
            `${formattedDisplayName[1].trim()} ${formattedDisplayName[0].trim()}`
          ),
        }
      );
    }

    if (
      (this.state.employee.signingPhase === "supervisor" &&
        (user.isSupervisor || user.isProxy)) ||
      (this.state.employee.signingPhase === "authorizedIndividual" &&
        user.linkblue === this.state.employee.authorizedIndividualLinkBlueId &&
        this.state.employee.supervisorSignatureNotDesignated)
    ) {
      // // First name is updated
      // if (
      //   String(record.target.employeeFirstName.value) !==
      //   String(this.state.employee.employeeFirstName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/employeeFirstName",
      //     value: encodeBase64(record.target.employeeFirstName.value),
      //   });
      // }

      // // Last name is updated
      // if (
      //   String(record.target.employeeLastName.value) !==
      //   String(this.state.employee.employeeLastName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/employeeLastName",
      //     value: encodeBase64(record.target.employeeLastName.value),
      //   });
      // }

      // // Forwarding Address is updated
      // if (
      //   String(record.target.forwardingAddress.value) !==
      //   String(this.state.employee.forwardingAddress)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/forwardingAddress",
      //     value: encodeBase64(record.target.forwardingAddress.value),
      //   });
      // }

      // // Org Unit is updated
      // if (
      //   String(record.target.orgUnit.value) !==
      //   String(this.state.employee.orgUnit)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/orgUnit",
      //     value: encodeBase64(record.target.orgUnit.value),
      //   });
      // }

      // // Dept Name is updated
      // if (
      //   String(record.target.deptName.value) !==
      //   String(this.state.employee.deptName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/deptName",
      //     value: encodeBase64(record.target.deptName.value),
      //   });
      // }

      // // Position # is updated
      // if (
      //   String(record.target.positionCode.value) !==
      //   String(this.state.employee.positionCode)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/positionCode",
      //     value: encodeBase64(record.target.positionCode.value),
      //   });
      // }

      // // Division is updated
      // if (
      //   String(record.target.division.value) !==
      //   String(this.state.employee.division)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/division",
      //     value: encodeBase64(record.target.division.value),
      //   });
      // }

      // // First Day Worked is updated
      // const formattedFirstDateWorked =
      //   this.state.employee.firstDateWorked === null
      //     ? ""
      //     : moment(this.state.employee.firstDateWorked).format("YYYY-MM-DD");
      // if (record.target.firstDateWorked.value !== formattedFirstDateWorked) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/firstDateWorked",
      //     value: record.target.firstDateWorked.value,
      //   });
      // }

      // // First Day Title is updated
      // if (
      //   String(record.target.separationFirstDayTitle.value) !==
      //   String(this.state.employee.separationFirstDayTitle)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationFirstDayTitle",
      //     value: encodeBase64(record.target.separationFirstDayTitle.value),
      //   });
      // }

      // // First Day Status is updated
      // if (
      //   String(record.target.separationFirstDayStatusTempRegular.value) !==
      //   String(this.state.employee.separationFirstDayStatusTempRegular)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationFirstDayStatusTempRegular",
      //     value: encodeBase64(
      //       record.target.separationFirstDayStatusTempRegular.value
      //     ),
      //   });
      // }

      // // First Day PT/FT is updated
      // if (
      //   String(record.target.firstDayStatusPTFT.value) !==
      //   String(this.state.employee.firstDayStatusPTFT)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/firstDayStatusPTFT",
      //     value: encodeBase64(record.target.firstDayStatusPTFT.value),
      //   });
      // }

      // // Last Day Worked is updated
      // const formattedLastDateWorked =
      //   this.state.employee.lastDateWorked === null
      //     ? ""
      //     : moment(this.state.employee.lastDateWorked).format("YYYY-MM-DD");
      // if (record.target.lastDateWorked.value !== formattedLastDateWorked) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDateWorked",
      //     value: record.target.lastDateWorked.value,
      //   });
      // }

      // // Last Day Title is updated
      // if (
      //   String(record.target.lastDayTitle.value) !==
      //   String(this.state.employee.lastDayTitle)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDayTitle",
      //     value: encodeBase64(record.target.lastDayTitle.value),
      //   });
      // }

      // // Last Day PT/FT is updated
      // if (
      //   String(record.target.lastDayStatusPTFT.value) !==
      //   String(this.state.employee.lastDayStatusPTFT)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDayStatusPTFT",
      //     value: encodeBase64(record.target.lastDayStatusPTFT.value),
      //   });
      // }

      // // Date Notice Given is updated
      // const formattedDateNoticeGiven =
      //   this.state.employee.dateNoticeGiven === null
      //     ? ""
      //     : moment(this.state.employee.dateNoticeGiven).format("YYYY-MM-DD");
      // if (record.target.dateNoticeGiven.value !== formattedDateNoticeGiven) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/dateNoticeGiven",
      //     value: record.target.dateNoticeGiven.value,
      //   });
      // }

      // // Notice Given Via is updated
      // if (
      //   String(record.target.separationNoticeGivenVia.value) !==
      //   String(this.state.employee.separationNoticeGivenVia)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationNoticeGivenVia",
      //     value: encodeBase64(record.target.separationNoticeGivenVia.value),
      //   });
      // }

      // // Separation Date is updated
      // const formattedSeparationDate =
      //   this.state.employee.separationDate === null
      //     ? ""
      //     : moment(this.state.employee.separationDate).format("YYYY-MM-DD");
      // if (record.target.separationDate.value !== formattedSeparationDate) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationDate",
      //     value: record.target.separationDate.value,
      //   });
      // }

      // Separation Reason is updated
      if (
        String(record.target.separationReasonExplanation.value) !==
        String(this.state.employee.separationReasonExplanation)
      ) {
        patchJson.push({
          op: "replace",
          path: "/separationReasonExplanation",
          value: encodeBase64(record.target.separationReasonExplanation.value),
        });
      }
    }

    let fetchUrl =
      window.REACT_APP_API_URL + "/records/" + this.state.employee.id;

    // if (window.REACT_APP_DEBUG) console.log("handleSubmitForm: " + fetchUrl);

    const signForm = async () => {
      try {
        const response = await fetch(fetchUrl, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.apiToken.accessToken}`,
          },
          body: JSON.stringify(patchJson),
        });
        if (response.ok) {
          this.handleChangeView("Employee Records");
        }
      } catch (error) {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error.message && error.message === "Failed to fetch") {
          this.setState({ fetchResponseStatusCode: 404 });
        }
      }
    };

    signForm();
  }

  render() {
    // Determine which view needs to show: form to sign or view-only display
    const showSigningForm = this.handleShowSigningForm(this.state.employee);

    let fullFormControls = {};

    if (showSigningForm) {
      fullFormControls = this.getFormControls(this.state.employee);
    }
    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col mt-4 mb-2">
            <h4>
              Separation Request for {this.state.employee.employeeFirstName}{" "}
              {this.state.employee.employeeLastName}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body pb-1">
                {this.state.isLoading && (
                  <div
                    className="text-center text-muted mb-4"
                    id="isLoadingDiv"
                  >
                    <div
                      className="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div
                      className="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}

                {!this.state.isLoading &&
                  this.state.fetchResponseStatusCode < 400 &&
                  showSigningForm && (
                    <Form
                      onSubmit={this.handleOnSubmit}
                      onChange={this.handleOnChange}
                      controls={fullFormControls}
                      record={this.state.employee}
                      submitButtonLabel="Sign Form"
                    />
                  )}
                {/* Logic to show view-only form */}
                {!this.state.isLoading &&
                  this.state.fetchResponseStatusCode < 400 &&
                  !showSigningForm && (
                    <ViewRecord
                      record={this.state.employee}
                      user={this.props.user}
                    />
                  )}

                {this.state.fetchResponseStatusCode > 399 && (
                  <ResponseStatusAlert
                    statusCode={this.state.fetchResponseStatusCode}
                    message={this.state.fetchResponseStatusMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeRecord;
