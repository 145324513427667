import React, { Component } from "react";
import $ from "jquery";
// @ukhc/devops-react-library
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import Search from "@ukhc/devops-react-library/components/Search";
import Table from "@ukhc/devops-react-library/components/Table";
import TableToolbar from "@ukhc/devops-react-library/components/TableToolbar";
// App components
import Legend from "./Legend";
import AddProxyModal from "./AddProxyModal";
import RemoveProxyModal from "./RemoveProxyModal";
import CancelSeparationModal from "./CancelSeparationModal";
import EmployeeSkipModal from "./EmployeeSkipModal";
import ViewFormModal from "./ViewFormModal";
import StatusFilterSelect from "./StatusFilterSelect";

import pdfMake from "pdfmake/build/pdfmake";
import { buildPDF } from "../utils/PDFBuilder";
import { formatFetchData, formatFetchUrl } from "../utils/Helpers";

export default class MyRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addProxyModalShow: false,
      removeProxyModalShow: false,
      cancelSeparationModalShow: false,
      viewFormModalShow: false,
      employeeSkipModalShow: false,
      currentPage: 1,
      fetchResponseStatusCode: null,
      isLoading: true,
      isSearching: false,
      isTableDisabled: true,
      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 25, 50, 100, 250, 500],
      records: [],
      search: null,
      selectedRecord: {},
      selectedRows: [],
      sortDescending: true,
      sortedBy: "separationDate",
      totalItems: null,
      totalPages: null,
      uidProperty: "id",
      statusFilter: "",
      canceledFilter: "",
      readyForReviewFilter: "",
      employeeSignedFilter: "",
      employeeSignatureNotDesignatedFilter: "",
      supervisorSignedFilter: "",
      supervisorSignatureNotDesignatedFilter: "",
      authorizedIndividualSignedFilter: "",
      authorizedIndividualSignatureNotDesignatedFilter: "",
      hrCompleteFilter: "",
    };

    this.handleAddProxyButtonClick = this.handleAddProxyButtonClick.bind(this);
    this.handleRemoveProxyButtonClick =
      this.handleRemoveProxyButtonClick.bind(this);
    this.handleCancelSeparationButtonClick =
      this.handleCancelSeparationButtonClick.bind(this);
    this.handleViewButtonClick = this.handleViewButtonClick.bind(this);
    this.handleEmployeeSkipButtonClick =
      this.handleEmployeeSkipButtonClick.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSetItemsPerPage = this.handleSetItemsPerPage.bind(this);
    this.handleSetPage = this.handleSetPage.bind(this);
    this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
    this.handleSetSortedBy = this.handleSetSortedBy.bind(this);
    this.handleSetSortDescending = this.handleSetSortDescending.bind(this);
    this.handleSetStatusFilter = this.handleSetStatusFilter.bind(this);
  }

  // for the data refresh interval
  intervalID;

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    this.handleFetch();

    // call handleFetch() every 60 seconds
    this.intervalID = setInterval(this.handleFetch, 60000);
  }

  componentWillUnmount() {
    // Stop handleFetch() from continuing to run even after unmounting component
    clearInterval(this.intervalID);
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleCancelSeparationButtonClick() {
    this.setState(
      {
        cancelSeparationModalShow: true,
      },
      () => {
        $("#cancelSeparationModal").modal("show");
      }
    );
  }

  handleAddProxyButtonClick() {
    this.setState(
      {
        addProxyModalShow: true,
      },
      () => {
        $("#addProxyModal").modal("show");
      }
    );
  }

  handleRemoveProxyButtonClick() {
    this.setState(
      {
        removeProxyModalShow: true,
      },
      () => {
        $("#removeProxyModal").modal("show");
      }
    );
  }

  handleFetch() {
    if (window.REACT_APP_DEBUG) console.log("Records: handlefetch");

    const url = formatFetchUrl(
      "records",
      this.props.user.linkblue,
      this.state.itemsPerPage,
      this.state.currentPage,
      this.state.sortedBy,
      this.state.sortDescending,
      this.state.search,
      this.state.readyForReviewFilter,
      this.state.employeeSignedFilter,
      this.state.employeeSignatureNotDesignatedFilter,
      this.state.supervisorSignedFilter,
      this.state.supervisorSignatureNotDesignatedFilter,
      this.state.authorizedIndividualSignedFilter,
      this.state.authorizedIndividualSignatureNotDesignatedFilter,
      this.state.hrCompleteFilter,
      this.state.canceledFilter
    );

    if (window.REACT_APP_DEBUG)
      console.log("Records handleFetch records: " + url);

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // get pagination from header
          let pagination = JSON.parse(response.headers.get("x-pagination"));
          this.setState({
            currentPage: pagination.currentPage,
            fetchResponseStatusCode: response.status,
            totalPages: pagination.totalPages,
            totalItems: pagination.totalItems,
          });

          return response.json();
        }
        this.setState({ fetchResponseStatusCode: response.status });
        throw response.status;
      })
      .then((data) => {
        const formattedData = formatFetchData(data, this.props.user);

        if (window.REACT_APP_DEBUG) console.log(formattedData);

        this.setState({
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          records: formattedData || [],
        });

        // Update the selected record
        if (this.state.selectedRows.length > 0 && data.length > 0) {
          let selectedRow = this.state.selectedRows[0];
          let selectedRecordValue = {};
          selectedRecordValue = data.filter((record) => {
            return record.id === selectedRow.uid;
          })[0];
          this.setState({
            selectedRecord: selectedRecordValue,
          });
        }
      })
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error === 404) {
          this.setState({
            fetchResponseStatusCode: 404,
            fetchResponseStatusMessage: "No records found.",
          });
        }

        this.setState({
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          records: [],
          totalPages: 0,
          totalItems: 0,
        });
      });
  }

  handleGeneratePDFButtonClick(record) {
    const docDefinition = buildPDF(record);
    pdfMake.createPdf(docDefinition).open();
  }

  handleSearch(search) {
    this.setState(
      {
        isSearching: true,
        search: search,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetItemsPerPage(limit) {
    this.setState(
      {
        itemsPerPage: limit,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetPage(page) {
    this.setState(
      {
        currentPage: page,
        selectedRecord: {},
        selectedRows: [],
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetSelectedRows(rows) {
    if (rows) {
      var selectedRecord = {};
      if (rows.length > 0) {
        selectedRecord = this.state.records.filter((record) => {
          return record.id === rows[0].uid;
        })[0];
      }
      if (window.REACT_APP_DEBUG) {
        console.log("handleSetSelectedRows:");
        console.log(selectedRecord);
      }

      this.setState({
        selectedRows: rows,
        selectedRecord: selectedRecord,
      });
    } else {
      this.setState({
        selectedRows: [],
        selectedRecord: {},
      });
    }
  }

  handleSetSortedBy(field) {
    if (window.REACT_APP_DEBUG) console.log("handleSetSortedBy: " + field);
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortedBy: field,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetStatusFilter(value) {
    switch (value) {
      case "Ready for review":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "true",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Incomplete":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "false",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Complete":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "true",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from individual authorized to remove employee from payroll":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "false",
            authorizedIndividualSignedFilter: "false",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from supervisor":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "false",
            supervisorSignedFilter: "false",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Needs signature from employee":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "false",
            employeeSignedFilter: "false",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "false",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      case "Canceled":
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "true",
          },
          () => {
            this.handleFetch();
          }
        );
        break;
      default:
        this.setState(
          {
            authorizedIndividualSignatureNotDesignatedFilter: "",
            authorizedIndividualSignedFilter: "",
            currentPage: 1,
            employeeSignatureNotDesignatedFilter: "",
            employeeSignedFilter: "",
            hrCompleteFilter: "",
            readyForReviewFilter: "",
            selectedRecord: {},
            selectedRows: [],
            supervisorSignatureNotDesignatedFilter: "",
            supervisorSignedFilter: "",
            canceledFilter: "",
          },
          () => {
            this.handleFetch();
          }
        );
    }
  }

  handleViewButtonClick() {
    this.setState(
      {
        viewFormModalShow: true,
      },
      () => {
        $("#viewFormModal").modal("show");
      }
    );
  }

  handleSetSortDescending(boolValue) {
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortDescending: boolValue,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleEmployeeSkipButtonClick() {
    this.setState(
      {
        employeeSkipModalShow: true,
      },
      () => {
        $("#employeeSkipModal").modal("show");
      }
    );
  }

  render() {
    let viewFormModalClose = () => {
      this.setState(
        {
          viewFormModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let cancelSeparationModalClose = () => {
      this.setState(
        {
          cancelSeparationModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let addProxyModalClose = () => {
      this.setState(
        {
          addProxyModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let removeProxyModalClose = () => {
      this.setState(
        {
          removeProxyModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    let employeeSkipModalClose = () => {
      this.setState(
        {
          employeeSkipModalShow: false,
        },
        () => {
          this.handleFetch();
        }
      );
    };

    // get the selected records into an array
    var selectedRecords = [];
    for (const row of this.state.selectedRows) {
      let rowToAdd = this.state.records.filter(
        (record) => record[this.state.uidProperty] === row.uid
      )[0];
      if (rowToAdd) {
        selectedRecords.push(rowToAdd);
      }
    }

    var resultsCountString = "";
    if (this.state.totalItems > 0) {
      resultsCountString =
        this.state.currentPage * this.state.itemsPerPage -
        (this.state.itemsPerPage - 1) +
        "-" +
        (this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage +
          this.state.records.length) +
        " of " +
        this.state.totalItems;
    } else {
      resultsCountString = "0 items";
    }

    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col mt-4 col-md-3 mb-2">
            <h4>My Requests</h4>
          </div>
          <div className="col text-right mt-3">
            <Legend />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body pb-1">
                <div className="row mb-3">
                  <div className="col col-12 col-sm-6">
                    <StatusFilterSelect
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      handleSetFilter={this.handleSetStatusFilter}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-12 col-md-4">
                    <Search
                      isSearching={this.state.isSearching}
                      onChange={this.handleSearch}
                    />
                  </div>
                  <div className="col col-12 col-md-8 text-right pr-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <TableToolbar
                        deleteMultiple={false}
                        disabled={
                          this.state.isTableDisabled ||
                          (this.state.fetchResponseStatusCode > 399 &&
                            this.state.fetchResponseStatusCode !== 404)
                        }
                        editMultiple={false}
                        selectedRecords={selectedRecords}
                        showAddButton={false}
                        showDeleteButton={false}
                        showEditButton={false}
                        showExportButton={false}
                      />
                      {selectedRecords.length === 1 && (
                        <button
                          type="button"
                          id="viewButton"
                          className="btn btn-link btn-sm"
                          disabled={
                            this.props.disabled ||
                            this.state.isTableDisabled ||
                            (this.state.fetchResponseStatusCode > 399 &&
                              this.state.fetchResponseStatusCode !== 404)
                          }
                          onClick={this.handleViewButtonClick}
                          style={{
                            fontSize: 13,
                            color: "#0067d5",
                          }}
                        >
                          <i className="bi bi-eye"></i> View
                        </button>
                      )}
                      {selectedRecords.length === 1 &&
                        !selectedRecords[0].hrComplete &&
                        !selectedRecords[0].canceled && (
                          <button
                            type="button"
                            id="addProxyButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleAddProxyButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-person-plus"></i> Assign Proxy
                          </button>
                        )}
                      {selectedRecords.length === 1 &&
                        selectedRecords[0].proxyName &&
                        selectedRecords[0].proxyLinkBlueId &&
                        !selectedRecords[0].hrComplete &&
                        !selectedRecords[0].canceled && (
                          <button
                            type="button"
                            id="removeProxyButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleRemoveProxyButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-person-x"></i> Remove Proxy
                          </button>
                        )}

                      {selectedRecords.length === 1 &&
                        !selectedRecords[0].hrComplete &&
                        !selectedRecords[0].canceled && (
                          <button
                            type="button"
                            id="cancelSeparationButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleCancelSeparationButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-x-circle"></i> Cancel
                          </button>
                        )}
                      {selectedRecords.length === 1 &&
                        !selectedRecords[0].skipEmployeeSignature &&
                        !selectedRecords[0].employeeSigned &&
                        !selectedRecords[0].canceled &&
                        !selectedRecords[0].hrComplete &&
                        !selectedRecords[0].employeeSignatureNotDesignated && (
                          <button
                            type="button"
                            id="employeeSkipButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={this.handleEmployeeSkipButtonClick}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-arrow-return-right"></i> Skip
                            Employee Signature
                          </button>
                        )}
                      {selectedRecords.length === 1 &&
                        !selectedRecords[0].canceled && (
                          <button
                            type="button"
                            id="generatePDFButton"
                            className="btn btn-link btn-sm"
                            disabled={
                              this.props.disabled ||
                              this.state.isTableDisabled ||
                              (this.state.fetchResponseStatusCode > 399 &&
                                this.state.fetchResponseStatusCode !== 404)
                            }
                            onClick={() => {
                              this.handleGeneratePDFButtonClick(
                                selectedRecords[0]
                              );
                            }}
                            style={{
                              fontSize: 13,
                              color: "#0067d5",
                            }}
                          >
                            <i className="bi bi-cloud-download"></i> Download
                            PDF
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="row no-gutters pt-3">
                  <div
                    className="col overflow-auto"
                    style={{ height: `calc(100vh - 373px)` }}
                  >
                    <Table
                      checkboxSelectOnly={false}
                      colorRowWarningProperty="missingSignatures"
                      colorRowWarningValue={true}
                      colorRowSuccessProperty="readyForReview"
                      colorRowSuccessValue={true}
                      colorRowSecondaryProperty="canceled"
                      colorRowSecondaryValue={true}
                      columns={tableColumns}
                      disabled={this.state.isTableDisabled}
                      handleSetSelectedRows={this.handleSetSelectedRows}
                      handleSetSortedBy={this.handleSetSortedBy}
                      handleSetSortDescending={this.handleSetSortDescending}
                      isBordered={false}
                      isLoading={this.state.isLoading}
                      isMultiSelect={false}
                      isSmall={true}
                      records={this.state.records}
                      responseStatusCode={this.state.fetchResponseStatusCode}
                      responseStatusMessage={
                        this.state.fetchResponseStatusMessage
                      }
                      selectedRows={this.state.selectedRows}
                      sortDescending={this.state.sortDescending}
                      sortedBy={this.state.sortedBy}
                      uidProperty={this.state.uidProperty}
                      useHoverableRows={true}
                      useStripedRows={false}
                    />
                  </div>
                </div>
                <hr className="my-0 py-0" />
                <div className="row no-gutters">
                  <div className="col mt-1">
                    <ItemsPerPage
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      onChange={this.handleSetItemsPerPage}
                      options={this.state.itemsPerPageOptions}
                      value={this.state.itemsPerPage}
                    />
                  </div>
                  <div className="col align-middle">
                    <div>
                      <center>
                        <div className="mt-2 text-muted">
                          {resultsCountString}
                        </div>
                      </center>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end py-1">
                    <Pagination
                      currentPage={this.state.currentPage}
                      disabled={
                        this.state.isTableDisabled ||
                        (this.state.fetchResponseStatusCode > 399 &&
                          this.state.fetchResponseStatusCode !== 404)
                      }
                      onChange={this.handleSetPage}
                      totalPages={Math.ceil(
                        this.state.totalItems / this.state.itemsPerPage
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.viewFormModalShow && this.state.selectedRecord !== null && (
          <ViewFormModal
            onHide={viewFormModalClose}
            record={this.state.selectedRecord}
            show={this.state.viewFormModalShow}
            user={this.props.user}
          />
        )}
        {this.state.addProxyModalShow && (
          <AddProxyModal
            onHide={addProxyModalClose}
            record={this.state.selectedRecord}
            show={this.state.addProxyModalShow}
            user={this.props.user}
          />
        )}
        {this.state.removeProxyModalShow && (
          <RemoveProxyModal
            onHide={removeProxyModalClose}
            record={this.state.selectedRecord}
            show={this.state.removeProxyModalShow}
            user={this.props.user}
          />
        )}
        {this.state.cancelSeparationModalShow && (
          <CancelSeparationModal
            onHide={cancelSeparationModalClose}
            record={this.state.selectedRecord}
            show={this.state.cancelSeparationModalShow}
            user={this.props.user}
          />
        )}
        {this.state.employeeSkipModalShow && (
          <EmployeeSkipModal
            onHide={employeeSkipModalClose}
            record={this.state.selectedRecord}
            show={this.state.employeeSkipModalShow}
            user={this.props.user}
          />
        )}
      </div>
    );
  }
}

let tableColumns = [
  {
    name: "PR #",
    property: "prNumber",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Employee",
    property: "employeeNameFormatted",
    sortable: true,
    sortableProperty: "employeeLastName",
    nowrap: true,
  },
  {
    name: "Employee ID",
    property: "employeePersonId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Employee linkblue",
    property: "employeeLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Separation Date",
    property: "separationDateFormatted",
    sortableProperty: "separationDate",
    sortable: true,
    align: "center",
    nowrap: true,
  },
  {
    name: "Employee Sub-Group",
    property: "employeeSubGroup",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Department Name",
    property: "deptName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Department #",
    property: "orgUnit",
    sortable: true,
    nowrap: true,
    align: "center",
  },
  {
    name: "Supervisor",
    property: "supervisorNameFormatted",
    sortableProperty: "supervisorName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Supervisor linkblue",
    property: "supervisorLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Proxy",
    property: "proxyName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Proxy linkblue",
    property: "proxyLinkBlueId",
    sortable: true,
    nowrap: true,
  },
  {
    align: "center",
    name: "Supervisor Signature",
    // property: "supervisorSignedFormatted",
    property: "supervisorSignatureColumn",
    sortable: true,
    sortableProperty: "supervisorSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Supervisor Signature Date",
  //   property: "supervisorSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "supervisorSignatureDate",
  //   nowrap: true,
  // },
  {
    align: "center",
    name: "Individual Authorized Signature",
    // property: "authorizedIndividualSignedFormatted",
    property: "individualAuthorizedSignatureColumn",
    sortable: true,
    sortableProperty: "authorizedIndividualSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Individual Authorized Signature Date",
  //   property: "authorizedIndividualSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "authorizedIndividualSignatureDate",
  //   nowrap: true,
  // },
  {
    align: "center",
    name: "Employee Signature",
    // property: "employeeSignedFormatted",
    property: "employeeSignatureColumn",
    sortable: true,
    sortableProperty: "employeeSigned",
    nowrap: true,
  },
  // {
  //   align: "center",
  //   name: "Employee Signature Date",
  //   property: "employeeSignatureDateFormatted",
  //   sortable: true,
  //   sortableProperty: "employeeSignatureDate",
  //   nowrap: true,
  // },
  {
    name: "Comments",
    property: "commentsToShow",
    align: "center",
  },
];
