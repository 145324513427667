import React, { Component } from "react";
// App components
import ViewRecordCol from "./ViewRecordCol";

class ViewRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastDayStatus: [],
    };
  }

  componentDidMount() {
    var lastDayStatus = [];

    switch (this.props.record.lastDayStatusPTFT) {
      case "Reg FT":
        lastDayStatus = ["Regular", "Full Time"];
        break;
      case "Temp PT >.20":
        lastDayStatus = ["Temporary", "Part Time"];
        break;
      case "Temp PT <.20":
        lastDayStatus = ["Temporary", "Part Time"];
        break;
      case "Temp FT":
        lastDayStatus = ["Temporary", "Full Time"];
        break;
      case "Non-Service":
        lastDayStatus = ["", ""];
        break;
      case "Phased Retiree":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      case "Reg PT >.74":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      case "Reg PT-Faculty":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      case "Unpaid":
        lastDayStatus = ["", ""];
        break;
      case "Reg PT .50-.74":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      case "On Call":
        lastDayStatus = ["Temporary", "Part Time"];
        break;
      case "Prem On Call":
        lastDayStatus = ["Temporary", "Part Time"];
        break;
      case "WEPP":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      case "Reg PT .20-.49":
        lastDayStatus = ["Regular", "Part Time"];
        break;
      default:
        lastDayStatus = ["", ""];
    }

    this.setState({
      lastDayStatus: lastDayStatus,
    });
  }

  render() {
    const employeeRecord = this.props.record;

    return (
      <div>
        <div className="row">
          {employeeRecord.employeeNameFormatted !== null &&
            employeeRecord.employeeNameFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Employee Name"
                value={employeeRecord.employeeNameFormatted}
              />
            )}
          {employeeRecord.employeePersonId !== null &&
            employeeRecord.employeePersonId !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Person ID"
                value={employeeRecord.employeePersonId}
              />
            )}
          {employeeRecord.forwardingAddress !== null &&
            employeeRecord.forwardingAddress !== "" && (
              <ViewRecordCol
                cols="col-12 col-md-6"
                label="Forwarding Address"
                value={employeeRecord.forwardingAddress}
              />
            )}
        </div>
        <div className="row">
          {employeeRecord.orgUnit !== null && employeeRecord.orgUnit !== "" && (
            <ViewRecordCol
              cols="col-12 col-sm-6 col-lg-3"
              label="Org. Unit"
              value={employeeRecord.orgUnit}
            />
          )}
          {employeeRecord.deptName !== null &&
            employeeRecord.deptName !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Dept. Name"
                value={employeeRecord.deptName}
              />
            )}
          {employeeRecord.positionCode !== null &&
            employeeRecord.positionCode !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Position #"
                value={employeeRecord.positionCode}
              />
            )}
          {employeeRecord.division !== null &&
            employeeRecord.division !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Division"
                value={employeeRecord.division}
              />
            )}
        </div>
        <hr />
        <div className="row">
          {employeeRecord.firstDateWorkedFormatted !== null &&
            employeeRecord.firstDateWorkedFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="First Day Worked"
                value={employeeRecord.firstDateWorkedFormatted}
              />
            )}
          {employeeRecord.separationFirstDayTitle !== null &&
            employeeRecord.separationFirstDayTitle !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="First Day Title"
                value={employeeRecord.separationFirstDayTitle}
              />
            )}
          {employeeRecord.separationFirstDayStatusTempRegular !== null &&
            employeeRecord.separationFirstDayStatusTempRegular !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="First Day Status"
                value={employeeRecord.separationFirstDayStatusTempRegular}
              />
            )}
          {employeeRecord.firstDayStatusPTFT !== null &&
            employeeRecord.firstDayStatusPTFT !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="First Day PT/FT"
                value={employeeRecord.firstDayStatusPTFT}
              />
            )}
        </div>
        <div className="row">
          {employeeRecord.lastDateWorkedFormatted !== null &&
            employeeRecord.lastDateWorkedFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Last Day Worked"
                value={employeeRecord.lastDateWorkedFormatted}
              />
            )}
          {employeeRecord.lastDayTitle !== null &&
            employeeRecord.lastDayTitle !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Last Day Title"
                value={employeeRecord.lastDayTitle}
              />
            )}
          {employeeRecord.lastDayStatusPTFT !== null &&
            employeeRecord.lastDayStatusPTFT !== "" && (
              <>
                <ViewRecordCol
                  cols="col-12 col-sm-6 col-lg-3"
                  label="Last Day Status"
                  value={this.state.lastDayStatus[0]}
                />
                <ViewRecordCol
                  cols="col-12 col-sm-6 col-lg-3"
                  label="Last Day PT/FT"
                  value={this.state.lastDayStatus[1]}
                />
              </>
            )}
        </div>
        <hr />
        <div className="row">
          {employeeRecord.dateNoticeGivenFormatted !== null &&
            employeeRecord.dateNoticeGivenFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Notice Given"
                value={employeeRecord.dateNoticeGivenFormatted}
              />
            )}
          {employeeRecord.separationNoticeGivenVia !== null &&
            employeeRecord.separationNoticeGivenVia !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6 col-lg-3"
                label="Notice Type"
                value={employeeRecord.separationNoticeGivenVia}
              />
            )}
          {employeeRecord.separationDateFormatted !== null &&
            employeeRecord.separationDateFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6"
                label="Separation Date"
                value={employeeRecord.separationDateFormatted}
              />
            )}
        </div>
        <hr />
        {employeeRecord.separationReasonsFormatted !== null &&
          employeeRecord.separationReasonsFormatted !== "" && (
            <div className="row">
              <ViewRecordCol
                label="Reason(s) for Separation"
                value={employeeRecord.separationReasonsFormatted}
              />
            </div>
          )}
        {employeeRecord.separationReasonExplanation !== null &&
          employeeRecord.separationReasonExplanation !== "" && <hr />}
        {employeeRecord.separationReasonExplanation !== null &&
          employeeRecord.separationReasonExplanation !== "" && (
            <div className="row">
              <ViewRecordCol
                label="Explanation for Separation"
                value={employeeRecord.separationReasonExplanation}
              />
            </div>
          )}
        {employeeRecord.separationSectionDEmployeeComments !== null &&
          employeeRecord.separationSectionDEmployeeComments !== "" && <hr />}
        {employeeRecord.separationSectionDEmployeeComments !== null &&
          employeeRecord.separationSectionDEmployeeComments !== "" && (
            <div className="row" style={{ wordWrap: "break-word" }}>
              <ViewRecordCol
                label="Employee's Comments"
                value={employeeRecord.separationSectionDEmployeeComments}
              />
            </div>
          )}
        <hr />
        <div className="row">
          {employeeRecord.supervisorSigned &&
            employeeRecord.supervisorName !== null &&
            employeeRecord.supervisorName !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6"
                label="Supervisor"
                value={employeeRecord.supervisorProxySignature}
              />
            )}
          {employeeRecord.supervisorSigned &&
            employeeRecord.supervisorSignatureDateFormatted !== null &&
            employeeRecord.supervisorSignatureDateFormatted !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6"
                label="Supervisor Signature Date"
                value={employeeRecord.supervisorSignatureDateFormatted}
              />
            )}
        </div>

        <div className="row">
          {employeeRecord.authorizedIndividualSigned &&
            employeeRecord.authorizedIndividualName !== null &&
            employeeRecord.authorizedIndividualName !== "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6"
                label="Individual Authorized to Remove Employee From Payroll"
                value={employeeRecord.authorizedIndividualNameFormatted}
              />
            )}
          {employeeRecord.authorizedIndividualSigned &&
            employeeRecord.authorizedIndividualSignatureDateFormatted !==
              null &&
            employeeRecord.authorizedIndividualSignatureDateFormatted !==
              "" && (
              <ViewRecordCol
                cols="col-12 col-sm-6"
                label="Individual Authorized to Remove Employee From Payroll Signature Date"
                value={
                  employeeRecord.authorizedIndividualSignatureDateFormatted
                }
              />
            )}
        </div>
        {employeeRecord.skipEmployeeSignature ? (
          <div className="row">
            {/* <div className="col-12">
              <div className="alert alert-danger mb-0" style={{ fontSize: 13 }}>
                <p>
                  <strong>Employee unavailable/refused to sign.</strong>
                </p>
                <p className="mb-1">
                  <strong>Reason:</strong>
                </p>
                <p className="mb-0">{employeeRecord.skipEmployeeComment}</p>
              </div>
            </div> */}
            <ViewRecordCol
              cols="col-12 col-sm-6"
              label="Employee"
              value={employeeRecord.employeeNameFormatted}
            />
            <ViewRecordCol
              cols="col-12 col-sm-6"
              label="Employee Signature Date"
              value={<span>&#8211;</span>}
            />
            <div className="col-12">
              <div className="alert alert-danger mb-0" style={{ fontSize: 13 }}>
                <p className="mb-0">
                  <strong>Reason for not signing:</strong>{" "}
                  {employeeRecord.skipEmployeeComment}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            {employeeRecord.employeeSigned &&
              employeeRecord.employeeNameFormatted !== null &&
              employeeRecord.employeeNameFormatted !== "" && (
                <ViewRecordCol
                  cols="col-12 col-sm-6"
                  label="Employee"
                  value={employeeRecord.employeeNameFormatted}
                />
              )}
            {employeeRecord.employeeSigned &&
              employeeRecord.employeeSignatureDateFormatted !== null &&
              employeeRecord.employeeSignatureDateFormatted !== "" && (
                <ViewRecordCol
                  cols="col-12 col-sm-6"
                  label="Employee Signature Date"
                  value={employeeRecord.employeeSignatureDateFormatted}
                />
              )}
          </div>
        )}
      </div>
    );
  }
}

export default ViewRecord;
