import React from "react";
// react-select
import Select from "react-select";

const StatusFilterSelect = (props) => {
  const handleSetFilter = (event) => {
    if (props.handleSetFilter) {
      props.handleSetFilter(event.value);
    }
  };

  let defaultOption = { value: "", label: "Any status" };

  const options = [
    defaultOption,
    {
      value: "Ready for review",
      label: "Ready for review",
    },
    {
      value: "Incomplete",
      label: "Incomplete",
    },
    {
      value: "Complete",
      label: "Complete",
    },
    {
      value:
        "Needs signature from individual authorized to remove employee from payroll",
      label:
        "Needs signature from individual authorized to remove employee from payroll",
    },
    {
      value: "Needs signature from supervisor",
      label: "Needs signature from supervisor",
    },
    {
      value: "Needs signature from employee",
      label: "Needs signature from employee",
    },
    {
      value: "Canceled",
      label: "Canceled",
    },
  ];

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 32,
      baseUnit: 2,
    },
  });

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided) => ({ ...provided, fontSize: 14 }),
        valueContainer: (provided) => ({ ...provided, fontSize: 14 }),
      }}
      theme={customThemeFn}
      defaultValue={defaultOption}
      isDisabled={props.disabled}
      onChange={handleSetFilter}
      options={options}
    />
  );
};

export default StatusFilterSelect;
