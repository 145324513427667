import React, { Component } from "react";
import "./App.css";
// Azure AD authentication
import * as azureAD from "./utils/AzureAD";
import * as graph from "./utils/Graph";
// @ukhc/devops-react-library
import AppView from "@ukhc/devops-react-library/components/AppView";
import LandingPage from "@ukhc/devops-react-library/components/LandingPage";
// App components
import Records from "./components/Records";
import EmployeeRecord from "./components/EmployeeRecord";
import MyRequests from "./components/MyRequests";
import EmployeeComments from "./components/EmployeeComments";

class App extends Component {
  constructor(props) {
    super(props);

    // Determine where user came from: email or direct
    const queryParams = new URLSearchParams(window.location.search);
    if (window.REACT_APP_DEBUG) {
      console.log("pid", queryParams.get("pid"));
    }

    this.state = {
      aboutModalShow: false,
      selectedView: "",
      error: null,
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: true, // true here for first load
      user: {},
      pid: queryParams.get("pid"),
    };

    this.getUserProfile = this.getUserProfile.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleShowAboutModal = this.handleShowAboutModal.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.validateAPIRoles = this.validateAPIRoles.bind(this);
  }

  // for the data refresh interval
  intervalID;

  componentDidMount() {
    this.getUserProfile();
    // Refresh all tokens and user identity data on a schedule
    this.intervalID = setInterval(this.getUserProfile, 180000);
  }

  componentWillUnmount() {
    /*
          stop token refresh from continuing to run even
          after unmounting this component.
      */
    clearInterval(this.intervalID);
  }

  async login() {
    if (window.REACT_APP_DEBUG) console.log("login");
    this.setState({
      isSigningIn: true,
    });
    await azureAD.login();
    this.getUserProfile();
  }

  async logout() {
    await azureAD.logout();
    this.setState({
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: false,
      user: {},
    });
  }

  async getUserProfile() {
    // If there is a cached user we are already logged in
    var cachedUser = azureAD.getCachedUser();
    if (window.REACT_APP_DEBUG)
      console.log("cached user: " + JSON.stringify(cachedUser));

    if (cachedUser) {
      // User is authenticated
      // Get the graph token
      const graphToken = await azureAD.getToken(["user.read"]);
      if (window.REACT_APP_DEBUG)
        console.log("graphToken: " + JSON.stringify(graphToken));

      // Get user data from Graph
      if (graphToken) {
        var graphUser = await graph.getUserDetails(graphToken);
        if (window.REACT_APP_DEBUG)
          console.log("graphUser: " + JSON.stringify(graphUser));
        var graphPhoto = await graph.getUserProfilePhoto(graphToken);
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      // Get the token for the app api
      const apiToken = await azureAD.getToken([window.REACT_APP_API_SCOPE]);
      if (window.REACT_APP_DEBUG)
        console.log("apiToken: " + JSON.stringify(apiToken));

      // Roles
      if (apiToken) {
        // Turn the user roles into a hierarchical thing
        // The user object will carry a bool for each role
        // We also determine a top level, "primary" role for display
        var theRoles = "";

        // Set the initial role state
        var thePrimaryRole = "";
        var isAuthorized = false;
        var isSupervisor = false;
        var isProxy = false;
        var isRequestor = false;
        var isUser = false;
        var isHrManager = false;
        var isAdministrator = false;

        if (apiToken.idTokenClaims.roles) {
          // Only set these if we have roles in the token.
          theRoles = apiToken.idTokenClaims.roles.toString();
          if (window.REACT_APP_DEBUG) console.log("theRoles: " + theRoles);

          if (theRoles.includes("HRManager")) {
            if (window.REACT_APP_DEBUG) console.log("is HR Manager");
            thePrimaryRole = "Workforce Management";
            isAuthorized = true;
            isUser = true;
            isHrManager = true;
          }

          if (theRoles.includes("Administrator")) {
            if (window.REACT_APP_DEBUG) console.log("is Admin");
            thePrimaryRole = "Administrator";
            isAuthorized = true;
            isUser = true;
            isAdministrator = true;
          }
        } else {
          // user has no roles in the token, set as Default User
          thePrimaryRole = "User";
          isAuthorized = true;
          isUser = true;
        }
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      // Get the roles from the API
      const apiRoles = await this.validateAPIRoles(apiToken);
      if (apiRoles) {
        if (apiRoles.includes("Proxy")) {
          if (window.REACT_APP_DEBUG) console.log("is Proxy");
          isProxy = true;
          isAuthorized = true;
          if (!isAdministrator && !isHrManager) {
            thePrimaryRole = "Proxy";
          }
        }
        if (apiRoles.includes("Supervisor")) {
          if (window.REACT_APP_DEBUG) console.log("is Supervisor");
          isSupervisor = true;
          isAuthorized = true;
          if (!isAdministrator && !isHrManager) {
            thePrimaryRole = "Supervisor";
          }
        }
        if (apiRoles.includes("Requestor")) {
          if (window.REACT_APP_DEBUG) console.log("is Requestor");
          isRequestor = true;
          isAuthorized = true;
          if (!isAdministrator && !isHrManager) {
            thePrimaryRole = "Requestor";
          }
        }
      }

      // Short linkblue
      var sUserName = apiToken.account.username.split("@");
      var linkblueValue = sUserName[0];

      //Set the state with the new user data
      this.setState({
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        isSigningIn: false,
        user: {
          displayName: graphUser.displayName,
          email: graphUser.mail || graphUser.userPrincipalName,
          isAdministrator: isAdministrator,
          isHrManager: isHrManager,
          isSupervisor: isSupervisor,
          isProxy: isProxy,
          isRequestor: isRequestor,
          isUser: isUser,
          linkblue: linkblueValue,
          primaryRole: thePrimaryRole,
          photo: graphPhoto,
          roles: theRoles,
          graphToken: graphToken,
          apiToken: apiToken,
          userName: apiToken.account.username,
        },
        error: null,
      });
    } else {
      // there was no user in the browser cache
      this.setState({
        isAuthenticated: false,
        isAuthorized: false,
        isSigningIn: false,
        user: {},
      });
    }
  }

  async validateAPIRoles(token) {
    var theResult = [];
    if (token) {
      var sUserName = token.account.username.split("@");
      var linkblue = sUserName[0];
      let fetchUrl =
        window.REACT_APP_API_URL + "/records/roles?linkBlueId=" + linkblue;
      await fetch(fetchUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          theResult = data;
        });
    }
    if (window.REACT_APP_DEBUG)
      console.log("validateManagerRole: " + theResult);
    return theResult;
  }

  handleChangeView(view) {
    if (window.REACT_APP_DEBUG) console.log("handleChangeView: " + view);
    this.setState({
      selectedView: view,
    });
  }

  handleShowAboutModal() {
    if (window.REACT_APP_DEBUG) console.log("handleShowAboutModal");
    this.setState({
      aboutModalShow: true,
    });
  }

  render() {
    if (window.REACT_APP_DEBUG) {
      console.log("isAuthenticated: " + this.state.isAuthenticated);
      console.log(JSON.stringify(this.state.user));
    }

    // Build the array of nav links for the header
    const hasAccessToEmployeeRecords =
      this.state.user.isAdministrator ||
      this.state.user.isSupervisor ||
      this.state.user.isProxy ||
      this.state.user.isHrManager ||
      this.state.user.isUser;

    let navigation = [];

    if (hasAccessToEmployeeRecords) {
      navigation.push({ name: "Employee Records" });
    }

    if (this.state.user.isRequestor) {
      navigation.push({ name: "My Requests" });
    }

    if (this.state.user.isAdministrator) {
      navigation.push({ name: "Employee Comments" });
    }

    if (this.state.user.isRequestor && !hasAccessToEmployeeRecords) {
    }

    if (this.state.selectedView === "") {
      let theSelectedView = "Employee Records";

      if (
        this.state.user.isRequestor &&
        !(
          this.state.user.isSupervisor ||
          this.state.user.isAdministrator ||
          this.state.user.isHrManager ||
          this.state.user.isProxy ||
          this.state.user.isUser
        )
      ) {
        theSelectedView = "My Requests";
      }

      if (
        this.state.user.isSupervisor ||
        this.state.user.isAdministrator ||
        this.state.user.isHrManager ||
        this.state.user.isProxy ||
        this.state.user.isUser
      ) {
        theSelectedView = "Employee Records";
      }

      if (this.state.pid) {
        theSelectedView = "Individual Record";
      }

      if (window.REACT_APP_DEBUG) {
        console.log("Selected view", theSelectedView);
      }

      this.setState({
        selectedView: theSelectedView,
      });
    }

    // TODO: Handle if user is requestor but not any others. Should see My Requests selected.
    var contentView = <div />;
    if (window.REACT_APP_DEBUG)
      console.log("other this.state.selectedView: " + this.state.selectedView);
    switch (this.state.selectedView) {
      case "Employee Records":
        contentView = <Records user={this.state.user} />;
        break;
      case "Employee Comments":
        contentView = <EmployeeComments user={this.state.user} />;
        break;
      case "Individual Record":
        contentView = (
          <EmployeeRecord
            user={this.state.user}
            pid={this.state.pid}
            handleChangeView={this.handleChangeView}
          />
        );
        break;
      case "My Requests":
        contentView = <MyRequests user={this.state.user} />;
        break;
      default:
        contentView = <Records user={this.state.user} />;
    }

    /* Login Screen */
    if (!this.state.isAuthenticated || !this.state.isAuthorized) {
      return (
        <LandingPage
          applicationName={window.REACT_APP_NAME}
          applicationDescription={window.REACT_APP_DESCRIPTION}
          isAuthenticated={this.state.isAuthenticated}
          isAuthorized={this.state.isAuthorized}
          isSigningIn={this.state.isSigningIn}
          login={this.login}
          logout={this.logout}
          userName={this.state.user.userName}
        />
      );
    }

    /* Main App View */
    return (
      <AppView
        applicationName={window.REACT_APP_NAME}
        applicationVersion={window.REACT_APP_VERSION}
        contentView={contentView}
        navigation={navigation}
        handleChangeView={this.handleChangeView}
        logout={this.logout}
        selectedView={this.state.selectedView}
        showSettingsGear={false}
        showSideNavbar={false}
        user={this.state.user}
      />
    );
  }
}

export default App;
