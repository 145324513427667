const supervisorFormControls = {
  formDescription:
    "If any of the information is incorrect, or has changed since it was initiated, please DO NOT SIGN. Contact your HR Business Partner for further instructions.",
  rows: [
    {
      cols: [
        {
          label: "Employee/Position Information",
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "First Name",
              property: "employeeFirstName",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Last Name",
              property: "employeeLastName",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Person ID",
              property: "employeePersonId",
              disabled: true,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Forwarding Address",
              property: "forwardingAddress",
              disabled: false,
              type: "text",
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Org. Unit",
              property: "orgUnit",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Dept. Name",
              property: "deptName",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Position #",
              property: "positionCode",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Division",
              property: "division",
              disabled: false,
              type: "text",
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "First Day Worked",
              property: "firstDateWorked",
              disabled: false,
              type: "date",
            },
          ],
        },
        {
          controls: [
            {
              label: "First Day Title",
              property: "separationFirstDayTitle",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "First Day Status",
              property: "separationFirstDayStatusTempRegular",
              disabled: false,
              type: "select",
              options: [
                { value: "", label: "--Select one--" },
                { value: "Temporary", label: "Temporary" },
                { value: "Regular", label: "Regular" },
              ],
            },
          ],
        },
        {
          controls: [
            {
              label: "First Day PT/FT",
              property: "firstDayStatusPTFT",
              disabled: false,
              type: "select",
              options: [
                { value: "", label: "--Select one--" },
                { value: "Part Time", label: "Part Time" },
                { value: "Full Time", label: "Full Time" },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Last Day Worked",
              property: "lastDateWorked",
              disabled: false,
              type: "date",
            },
          ],
        },
        {
          controls: [
            {
              label: "Last Day Title",
              property: "lastDayTitle",
              disabled: false,
              type: "text",
            },
          ],
        },
        {
          controls: [
            {
              label: "Last Day Status",
              property: "lastDayStatusTempRegular",
              disabled: false,
              type: "select",
              options: [
                { value: "", label: "--Select one--" },
                { value: "Temporary", label: "Temporary" },
                { value: "Regular", label: "Regular" },
              ],
            },
          ],
        },
        {
          controls: [
            {
              label: "Last Day PT/FT",
              property: "lastDayStatusPTFT",
              disabled: false,
              type: "select",
              options: [
                { value: "", label: "--Select one--" },
                { value: "Part Time", label: "Part Time" },
                { value: "Full Time", label: "Full Time" },
              ],
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Notice Given",
              property: "dateNoticeGiven",
              disabled: false,
              type: "date",
            },
          ],
        },
        {
          controls: [
            {
              label: "Notice Type",
              property: "separationNoticeGivenVia",
              disabled: false,
              type: "select",
              options: [
                { value: "", label: "--Select one--" },
                { value: "Written", label: "Written" },
                { value: "Oral", label: "Oral" },
                { value: "None", label: "None" },
              ],
            },
          ],
        },
        {
          controls: [
            {
              label: "Separation Date",
              property: "separationDate",
              disabled: false,
              type: "date",
            },
          ],
        },
        {
          controls: [],
        },
      ],
    },
    {
      cols: [
        {
          label: "Reason for Separation",
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Separation Reasons",
              property: "separationReasonsFormatted",
              disabled: true,
              type: "textarea",
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Explanation for Separation",
              property: "separationReasonExplanation",
              disabled: false,
              type: "textarea",
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          label: "Employee's Comments",
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Employee's Comments",
              property: "separationSectionDEmployeeComments",
              disabled: true,
              type: "textarea",
            },
          ],
        },
      ],
    },
    {
      cols: [
        {
          label: "Signature",
          description: "Initial below and click Sign Form.",
        },
      ],
    },
    {
      cols: [
        {
          controls: [
            {
              label: "Supervisor/Proxy",
              property: "supervisorProxySignature",
              disabled: false,
              required: true,
              type: "text",
            },
          ],
        },
        {
          controls: [],
        },
      ],
    },
  ],
};

export default supervisorFormControls;
