import React, { Component } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
// @ukhc/devops-react-library
import TextInput from "@ukhc/devops-react-library/components/TextInput";

export default class AssignProxyLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSearchButtonEnabled: false,
      isSubmitButtonEnabled: false,
      resultLabel: null,
      sapDisplayName: null,
      sapLinkBlueId: null,
      // sapStatus: null,
      search: "",
    };
    this.handleGetRecordFromSap = this.handleGetRecordFromSap.bind(this);
    this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleGetRecordFromSap() {
    this.setState({
      isLoading: true,
      isSearchButtonEnabled: false,
    });

    let fetchUrl =
      window.REACT_APP_API_URL +
      "/proxyassociations/proxy?search=" +
      this.state.search;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        // Record was found
        if (data.proxyLinkBlueId) {
          this.setState({
            isLoading: false,
            sapLinkBlueId: data.proxyLinkBlueId.toLowerCase(),
            sapDisplayName: data.proxyName,
            isSubmitButtonEnabled: true,
            resultLabel: "Found",
          });
        } else {
          // Result not found
          this.setState({
            isLoading: false,
            sapDisplayName: null,
            sapLinkBlueId: null,
            resultLabel: "Not Found",
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          sapDisplayName: null,
          sapLinkBlueId: null,
          // sapStatus: null,
          resultLabel: "Not Found",
        });
      });
  }

  handleSearchValueChange(value) {
    this.setState({
      isSearchButtonEnabled: true,
      isSubmitButtonEnabled: false,
      resultLabel: null,
      sapDisplayName: null,
      sapLinkBlueId: null,
      // sapStatus: null,
      search: value,
    });
  }

  handleSubmit() {
    this.setState({
      isSubmitButtonEnabled: false,
    });

    if (this.props.handleSubmit) {
      this.props.handleSubmit(
        this.state.sapDisplayName,
        this.state.sapLinkBlueId
      );
    }
  }

  render() {
    return (
      <div>
        <p className="font-weight-light">
          Grant another person access to act as proxy.
        </p>
        <TextInput
          helpText={
            this.state.sapLinkBlueId === null
              ? "Enter a linkblue and click the Search button"
              : "Change the search to start over."
          }
          label={
            this.state.sapLinkBlueId == null
              ? "Search for a person by linkblue"
              : "Searched for..."
          }
          onChange={this.handleSearchValueChange}
          property="search"
          showHelpButton={false}
        />
        {this.state.sapLinkBlueId === null && (
          <button
            className="btn btn-primary font-weight-bold rounded-sm px-5 mr-2"
            id="searchButton"
            disabled={!this.state.isSearchButtonEnabled}
            onClick={this.handleGetRecordFromSap}
            style={{
              fontSize: 12,
              letterSpacing: 0.25,
            }}
            type="button"
          >
            {this.state.isLoading ? "Searching..." : "Search"}
          </button>
        )}
        {this.state.resultLabel !== null && <hr />}
        {this.state.resultLabel !== null && (
          <h5
            className={
              this.state.resultLabel === "Found" ? "text-primary" : "text-muted"
            }
          >
            {this.state.resultLabel === "Found" && (
              <i className="bi bi-check-circle mr-2"></i>
            )}
            {this.state.resultLabel !== "Found" && (
              <i className="bi bi-info-circle mr-2"></i>
            )}
            linkblue {this.state.resultLabel}
          </h5>
        )}
        {this.state.sapLinkBlueId !== null && (
          <div className="text-muted">
            <p className="my-0">
              <strong>linkblue:</strong> {this.state.sapLinkBlueId}
            </p>
            <p className="my-0">
              <strong className="ml-3">Name:</strong>{" "}
              {this.state.sapDisplayName}
            </p>
          </div>
        )}
        {this.state.resultLabel !== null &&
          this.state.resultLabel !== "Found" && (
            <small className="text-dark">
              Modify the search above to try again.
            </small>
          )}
        {this.state.sapLinkBlueId !== null && <hr />}
        {this.state.sapLinkBlueId !== null && (
          <button
            className="btn btn-primary font-weight-bold rounded-sm px-5 mr-2"
            id="searchButton"
            disabled={!this.state.isSubmitButtonEnabled}
            onClick={this.handleSubmit}
            style={{
              fontSize: 12,
              letterSpacing: 0.25,
            }}
            type="button"
          >
            {this.state.resultLabel === "Found"
              ? "Assign Proxy"
              : "Cannot Assign Proxy"}
          </button>
        )}
      </div>
    );
  }
}
