import React, { Component } from "react";
// @ukhc/devops-react-library
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";
// App components
import supervisorFormControls from "./SupervisorFormControls";

export default class RecordEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (this.props.user.isSupervisor || this.props.user.isProxy) {
      this.state = {
        formControls: supervisorFormControls,
        submitButtonLabel: "Sign Form",
      };
    }

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    // Close modal
    document.getElementById("closeEditModalButton").click();
    this.props.onHide();
  }

  handleSubmitForm(record) {
    const user = this.props.user;
    let signedPath = "";
    let signatureDatePath = "";
    let moment = require("moment");

    if (user.isUser && user.linkblue === record.employeeLinkBlueId) {
      signedPath = "employeeSigned";
      signatureDatePath = "employeeSignatureDate";
    }

    if (user.isProxy || user.isSupervisor) {
      signedPath = signedPath + "supervisorSigned";
      signatureDatePath = "supervisorSignatureDate";
    }

    if (user.isAdministrator) {
      signedPath = "payrollOfficialSigned";
      signatureDatePath = "payrollOfficialSignatureDate";
    }

    let fetchUrl = window.REACT_APP_API_URL + "/records/" + record.id;

    if (window.REACT_APP_DEBUG) console.log("handleSubmitForm: " + fetchUrl);

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: `/${signedPath}`,
          value: true,
        },
        {
          op: "replace",
          path: `/${signatureDatePath}`,
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      ]),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        this.setState({ fetchResponseStatusCode: response.status });
        throw new Error(response.status);
      })
      .then((data) => {})
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error.message && error.message === "Failed to fetch") {
          this.setState({ fetchResponseStatusCode: 404 });
        }
      });
  }

  handleSignForm() {}

  render() {
    const record = this.props.record;

    return (
      <div
        className="modal fade"
        id="recordEditModal"
        role="dialog"
        aria-labelledby="recordEditModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-weight-bold"
                id="recordEditModalLongTitle"
              >
                <i className="bi bi-pencil text-primary mr-2"></i>{" "}
                {record.employeeFirstName} {record.employeeLastName}
              </h5>
              <button
                type="button"
                id="closeEditModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                id="recordedit-form"
                controls={this.state.formControls}
                onCancel={this.handleCloseModal}
                onSubmit={() => this.handleSubmitForm(record)}
                record={record}
                submitButtonLabel={this.state.submitButtonLabel}
              />
              {this.state.httpResponseStatusCode > 399 && (
                <ResponseStatusAlert
                  statusCode={this.state.httpResponseStatusCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
