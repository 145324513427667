import React, { Component } from "react";
// App components
import ViewRecord from "./ViewRecord";

class ViewFormModal extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    // Close modal
    document.getElementById("closeViewFormModalButton").click();
    this.props.onHide();
  }

  render() {
    return (
      <div
        className="modal fade"
        id="viewFormModal"
        role="dialog"
        aria-labelledby="viewFormModalCenterTitle"
        aria-hidden="true"
        onMouseDown={this.handleCloseModal}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          aria-hidden="true"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-weight-bold"
                id="viewFormModalLongTitle"
              >
                <i className="bi bi-pen text-primary mr-2"></i> Viewing record
                for {this.props.record.employeeFirstName}{" "}
                {this.props.record.employeeLastName}
              </h5>
              <button
                type="button"
                id="closeViewFormModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ViewRecord record={this.props.record} user={this.props.user} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewFormModal;
