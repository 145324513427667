export const PrimaryReasons = [
  {
    value: "voluntaryQuitRehire",
    label: "Voluntary Quit (Eligible for Rehire)",
  },
  {
    value: "lackOfWork",
    label: "Lack of Work (Eligible for Rehire)",
  },
  {
    value: "voluntaryQuitNoRehire",
    label: "Voluntary Quit (Not Eligible for Rehire)",
  },
  {
    value: "involuntaryDischarge",
    label: "Involuntary Discharge (Not Eligible for Rehire)",
  },
];

export const SecondaryReasons = [
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Self-Employment",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Conflict with Management",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Conflict with Co-Workers",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Staffing/Workload",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Lack of Respect",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Employee Relocation",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Spouse/Partner Relocation",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Travel/Agency Position (RNs only)",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Promotion/Advancement",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Obtained Position Representing Different Job Experience",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Desirable Work Schedule",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Desirable Commute",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Compensation/Pay",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Inability to Advance",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Domestic Responsibility",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Health",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Failed to Return from Leave",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "School Responsibility",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Leaving School",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Graduated",
  },
  {
    primaryReason: "Voluntary Quit (Eligible for Rehire)",
    label: "Other (Must Explain/Unknown)",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Funds Exhausted",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Position Eliminated",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Reorganization",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Assignment Ended",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Seasonal Work",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Other (Must Explain/Unknown)",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Contract Not Renewed",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Retirement",
  },
  {
    primaryReason: "Lack of Work (Eligible for Rehire)",
    label: "Deceased",
  },
  {
    primaryReason: "Voluntary Quit (Not Eligible for Rehire)",
    label: "Job Abandonment",
  },
  {
    primaryReason: "Voluntary Quit (Not Eligible for Rehire)",
    label: "Quit without Notice",
  },
  {
    primaryReason: "Voluntary Quit (Not Eligible for Rehire)",
    label: "Anticipation of Discharge",
  },
  {
    primaryReason: "Voluntary Quit (Not Eligible for Rehire)",
    label: "Did Not Fulfill Notice",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Insubordination",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Violation of Univ. or Dept. Rule",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Falsification of Records",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Falsification of Records-Employment Application",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Falsification of Records-Time Records",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Falsification of Records-Other Records",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Defaming/Maligning Reputation of University",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Dishonesty",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Imperiling Safety of Others",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Gambling",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Negligent Destruction of University Property",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Substance Abuse-Drugs",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Substance Abuse-Alcohol",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Violation of Local, State, or Federal Law",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Incarceration",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Physical or Mental Endangerment",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Fighting and/or Physical Assault",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Failure or Neglect to Perform Duties",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Lack of Skills for the Job",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Absence/Tardiness Problems",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Absence/Tardiness Problems-Excessive",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Absence/Tardiness Problems-Unauthorized",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Absence/Tardiness Problems-Unreported",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Absence/Tardiness Problems-Improperly Reported",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Failure to Act in a Courteous or Cooperative Manner",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Inappropriate or Unsuitable Job Performance",
  },
  {
    primaryReason: "Involuntary Discharge (Not Eligible for Rehire)",
    label: "Other (Must Explain/Unknown)",
  },
];

export const DeceasedFields = [
  {
    type: "employee",
    label: "Employee",
  },
  {
    type: "retiree",
    label: "Retire",
  },
];

export const falsificationOfRecordsFields = [
  {
    type: "employmentApplication",
    label: "Employment Application",
  },
  {
    type: "timeRecords",
    label: "Time Records",
  },
  {
    type: "otherRecords",
    label: "Other Records",
  },
];

export const absenceProblemsFields = [
  {
    type: "excessive",
    label: "Excessive",
  },
  {
    type: "unauthorized",
    label: "Unauthorized",
  },
  {
    type: "unreported",
    label: "Unreported",
  },
  {
    type: "improperlyReported",
    label: "Improperly Reported",
  },
];
