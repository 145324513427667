import React, { Component } from "react";
// @ukhc/devops-react-libary
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";

import pdfMake from "pdfmake/build/pdfmake";
import * as pdfUtils from "../utils/PDFBuilder";
import TextInput from "@ukhc/devops-react-library/components/TextInput";

import { encodeBase64 } from "../utils/Helpers";

class ApproveFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchResponseStatusCode: null,
      record: props.record || {},
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleCreateSeparationPDF = this.handleCreateSeparationPDF.bind(this);
  }

  handleCloseModal() {
    // Reset error message
    this.setState({
      fetchResponseStatusCode: null,
    });

    // Close modal
    document.getElementById("closeApproveFormModalButton").click();
    this.props.onHide();
  }

  handleCreateSeparationPDF() {
    const docDefinition = pdfUtils.buildPDF(this.state.record);
    pdfMake.createPdf(docDefinition).open();
  }

  handleOnSubmit(event) {
    let fetchUrl =
      window.REACT_APP_API_URL + "/records/" + this.state.record.id;

    if (window.REACT_APP_DEBUG) console.log("handleSubmitForm: " + fetchUrl);

    let moment = require("moment");

    var updateLastDayWorked = false;
    var updateSeparationDate = false;
    var updateTerminalHrsToBePaid = false;

    let patchBody = [
      {
        op: "replace",
        path: "/hrComplete",
        value: true,
      },
      {
        op: "replace",
        path: "/hrCompleteOn",
        value: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        op: "replace",
        path: "/hrCompleteBy",
        value: encodeBase64(this.props.user.linkblue),
      },
    ];

    // Terminal Hours to be Paid
    if (
      this.state.record.terminalHrsToBePaid !==
      event.target.terminalHrsToBePaid.value
    ) {
      updateTerminalHrsToBePaid = true;
      patchBody.push({
        op: "replace",
        path: "/terminalHrsToBePaid",
        value: encodeBase64(event.target.terminalHrsToBePaid.value),
      });
    }

    // Last Date Worked
    const lastDateWorkedFormatted =
      this.state.record.lastDateWorked === null
        ? ""
        : moment(this.state.record.lastDateWorked).format("YYYY-MM-DD");
    if (lastDateWorkedFormatted !== event.target.lastDateWorked.value) {
      updateLastDayWorked = true;
      patchBody.push({
        op: "replace",
        path: "/lastDateWorked",
        value: event.target.lastDateWorked.value,
      });
    }

    // Separation Date
    const separationDateFormatted =
      this.state.record.separationDate === null
        ? ""
        : moment(this.state.record.separationDate).format("YYYY-MM-DD");
    if (separationDateFormatted !== event.target.separationDate.value) {
      updateSeparationDate = true;
      patchBody.push({
        op: "replace",
        path: "/separationDate",
        value: event.target.separationDate.value,
      });
    }

    this.setState({
      record: {
        ...this.state.record,
        terminalHrsToBePaid: updateTerminalHrsToBePaid
          ? event.target.terminalHrsToBePaid.value
          : this.state.record.terminalHrsToBePaid,
        separationDate: updateSeparationDate
          ? event.target.separationDate.value
          : this.state.record.separationDate,
        lastDateWorked: updateLastDayWorked
          ? event.target.lastDateWorked.value
          : this.state.record.lastDateWorked,
      },
    });

    if (window.REACT_APP_DEBUG) {
      console.log("ApproveFormModal handleOnSubmit patch:", patchBody);
    }

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify(patchBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        this.setState({ fetchResponseStatusCode: response.status });
        throw new Error(response.status);
      })
      .then(() => {
        this.handleCloseModal();
      })
      .then(() => {
        this.handleCreateSeparationPDF();
      })
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error.message && error.message === "Failed to fetch") {
          this.setState({ fetchResponseStatusCode: 404 });
        }
      });
  }

  render() {
    const formControls = {
      rows: [
        {
          cols: [
            {
              label: "Employee/Position Information",
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Employee Name",
                  property: "employeeNameFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Person ID",
                  property: "employeePersonId",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Forwarding Address",
                  property: "forwardingAddress",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Org. Unit",
                  property: "orgUnit",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Dept. Name",
                  property: "deptName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Position #",
                  property: "positionCode",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Division",
                  property: "division",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "First Day Worked",
                  property: "firstDateWorkedFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Title",
                  property: "separationFirstDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Status",
                  property: "separationFirstDayStatusTempRegular",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day PT/FT",
                  property: "firstDayStatusPTFT",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Last Day Worked",
                  property: "lastDateWorked",
                  disabled: false,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Title",
                  property: "lastDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Status",
                  property: "lastDayStatusPTFT",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Notice Given",
                  property: "dateNoticeGivenFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Notice Type",
                  property: "separationNoticeGivenVia",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Separation Date",
                  property: "separationDate",
                  disabled: false,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Terminal Hrs to be Paid",
                  property: "terminalHrsToBePaid",
                  disabled: false,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              label: "Reason for Separation",
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Separation Reasons",
                  property: "separationReasonsFormatted",
                  disabled: true,
                  type: "textarea",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Explanation for Separation",
                  property: "separationReasonExplanation",
                  disabled: true,
                  type: "textarea",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              label: "Employee's Comments",
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Employee's Comments",
                  property: "separationSectionDEmployeeComments",
                  disabled: true,
                  type: "textarea",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              label: "Signatures",
              description: "Initial below and click Sign Form.",
            },
          ],
        },
      ],
    };
    if (
      !this.state.record.supervisorSignatureNotDesignated ||
      this.state.record.supervisorSigned
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Supervisor",
                property: "supervisorProxySignature",
                disabled: true,
                type: "text",
              },
            ],
          },
          {
            controls: [
              {
                label: "Supervisor Signature Date",
                property: "supervisorSignatureDateFormatted",
                disabled: true,
                type: "text",
              },
            ],
          },
        ],
      });
    }

    if (
      !this.state.record.authorizedIndividualSignatureNotDesignated ||
      this.state.record.payrollOfficialSigned
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Individual Authorized to Remove Employee From Payroll",
                property: "authorizedIndividualNameFormatted",
                disabled: true,
                type: "text",
              },
            ],
          },
          {
            controls: [
              {
                label:
                  "Individual Authorized to Remove Employee From Payroll Signature Date",
                property: "authorizedIndividualSignatureDateFormatted",
                disabled: true,
                type: "text",
              },
            ],
          },
        ],
      });
    }

    if (
      !this.state.record.employeeSignatureNotDesignated ||
      this.state.record.employeeOfficialSigned
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Employee",
                property: "employeeNameFormatted",
                disabled: true,
                type: "text",
              },
            ],
          },
          {
            controls: [
              {
                label: "Employee Signature Date",
                property: "employeeSignatureDateFormatted",
                disabled: true,
                type: "text",
              },
            ],
          },
        ],
      });
    }

    if (this.state.record.skipEmployeeSignature) {
      console.log(
        "skipEmployeeSignature",
        this.state.record.skipEmployeeSignature
      );
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                type: "custom",
                component: (
                  <div
                    className="alert alert-danger mb-0"
                    style={{ fontSize: 13 }}
                  >
                    <p className="mb-0">
                      <strong>Reason for not signing:</strong>{" "}
                      {this.state.record.skipEmployeeComment}
                    </p>
                  </div>
                ),
              },
            ],
          },
        ],
      });
    }

    return (
      <div
        className="modal fade"
        id="approveFormModal"
        role="dialog"
        aria-labelledby="approveFormModalCenterTitle"
        aria-hidden="true"
        onMouseDown={this.handleCloseModal}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
          aria-hidden="true"
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-weight-bold"
                id="approveFormModalLongTitle"
              >
                <i className="bi bi-check2-square text-primary mr-2"></i>{" "}
                Confirm signatures for {this.state.record.employeeFirstName}{" "}
                {this.state.record.employeeLastName}
              </h5>
              <button
                type="button"
                id="closeApproveFormModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onCancel={this.handleCloseModal}
                onSubmit={(event) => {
                  this.handleOnSubmit(event);
                }}
                isSubmitButtonEnabled={true}
                controls={formControls}
                record={this.state.record}
                submitButtonLabel="Approve &amp; Generate PDF"
              />
              {this.state.fetchResponseStatusCode > 399 && (
                <ResponseStatusAlert
                  statusCode={this.state.fetchResponseStatusCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApproveFormModal;
