import { useEffect, useState } from "react";
import $ from "jquery";
// @ukhc/devops-react-library
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import Search from "@ukhc/devops-react-library/components/Search";
import Table from "@ukhc/devops-react-library/components/Table";
import TableToolbar from "@ukhc/devops-react-library/components/TableToolbar";
import DateInput from "@ukhc/devops-react-library/components/DateInput";
// App components
import EmployeeCommentsExportModal from "./EmployeeCommentsExportModal";

import { DateTime } from "luxon";

export default function EmployeeComments(props) {
  const [fetchResponseStatusCode, setFetchResponseStatusCode] = useState(null);
  const [fetchResponseStatusMessage, setFetchResponseStatusMessage] =
    useState(null);
  const [fetchResponseStatusVariant, setFetchResponseStatusVariant] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [table, setTable] = useState({
    currentPage: 1,
    isTableDisabled: true,
    itemsPerPage: 25,
    itemsPerPageOptions: [5, 10, 25, 50, 100, 250, 500],
    selectedRecord: {},
    selectedRows: [],
    sortDescending: true,
    sortedBy: "separationDate",
    totalItems: null,
    totalPages: null,
    uidProperty: "id",
  });
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [excelExportData, setExcelExportData] = useState(null);
  const [commentsExportModalShow, setCommentsExportModalShow] = useState(false);
  const [fromSeparationDate, setFromSeparationDate] = useState(null); // YYYY-MM-DD
  const [toSeparationDate, setToSeparationDate] = useState(null); // YYYY-MM-DD

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    handleFetch();
  }, [
    search,
    table.itemsPerPage,
    table.currentPage,
    table.sortedBy,
    table.sortDescending,
    fromSeparationDate,
    toSeparationDate,
  ]);

  useEffect(() => {
    if (commentsExportModalShow) {
      $("#employeeCommentsExportModal").modal("show");
    }
  }, [commentsExportModalShow]);

  function formatData(data) {
    const formattedData = data;

    formattedData.forEach((element) => {
      if (element.separationDate) {
        element.separationDateFormatted = DateTime.fromISO(
          element.separationDate
        ).toFormat("MM/dd/yyyy");
      } else {
        element.separationDateFormatted = "";
      }

      element.employeeNameFormatted = `${element.employeeLastName}, ${element.employeeFirstName}`;

      if (element.supervisorName) {
        var supervisorWithoutNumber = element.supervisorName.split(" - ")[0];
        element.supervisorNameFormatted = `${supervisorWithoutNumber.trim()}`;
      } else {
        element.supervisorNameFormatted = "";
      }

      if (element.separationReasons.length > 0) {
        element.separationReasonsFormatted = Array.prototype.map
          .call(element.separationReasons, (item) => {
            return item.separationReasonText;
          })
          .join(", ");
      }
    });

    return formattedData;
  }

  function formatUrl(isExport = false) {
    const url = new URL(`${window.REACT_APP_API_URL}/reports/employeecomments`);

    url.searchParams.set("pageSize", table.itemsPerPage);
    url.searchParams.set("currentPage", table.currentPage);
    // Sorting
    url.searchParams.set("orderBy", table.sortedBy);
    if (table.sortDescending) {
      url.searchParams.set("orderByDirection", "desc");
    } else {
      url.searchParams.delete("orderByDirection");
    }

    // Searching
    if (search) {
      url.searchParams.set("search", search);
    }

    if (fromSeparationDate !== null) {
      url.searchParams.set(
        "fromSeparationDate",
        DateTime.fromISO(fromSeparationDate).toFormat("yyyy-MM-dd")
      );
    }

    if (toSeparationDate !== null) {
      url.searchParams.set(
        "toSeparationDate",
        DateTime.fromISO(toSeparationDate).toFormat("yyyy-MM-dd")
      );
    }

    if (isExport) {
      url.searchParams.set("export", "true");
    }

    return url.toString();
  }

  function handleFetch() {
    if (window.REACT_APP_DEBUG) console.log("Records: handlefetch");

    const fetchUrl = formatUrl();

    if (window.REACT_APP_DEBUG)
      console.log("Records handleFetch records: " + fetchUrl);

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // get pagination from header
          let pagination = JSON.parse(response.headers.get("x-pagination"));
          setTable((table) => ({
            ...table,
            currentPage: pagination.currentPage,
            totalPages: pagination.totalPages,
            totalItems: pagination.totalItems,
          }));

          setFetchResponseStatusCode(response.status);
          return response.json();
        }
        setFetchResponseStatusCode(response.status);
        throw response.status;
      })
      .then((data) => {
        const formattedData = formatData(data);

        setIsLoading(false);
        setIsSearching(false);
        setTable((table) => ({
          ...table,
          isTableDisabled: false,
        }));
        setRecords(formattedData || []);

        // Update the selected record
        if (table.selectedRows.length > 0 && data.length > 0) {
          let selectedRow = table.selectedRows[0];
          let selectedRecordValue = {};
          selectedRecordValue = data.filter((record) => {
            return record.id === selectedRow.uid;
          })[0];
          setTable((table) => ({
            ...table,
            selectedRecord: selectedRecordValue,
          }));
        }
      })
      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error === 404) {
          setFetchResponseStatusCode(404);
          // is to date before from date?
          if (toSeparationDate < fromSeparationDate) {
            setFetchResponseStatusMessage(
              `The "From" date must be earlier than the "To" date.`
            );
            setFetchResponseStatusVariant("danger");
          } else {
            setFetchResponseStatusMessage("No records found.");
          }
        }

        setTable((table) => ({
          ...table,
          isTableDisabled: false,
          totalPages: 0,
          totalItems: 0,
        }));
        setIsLoading(false);
        setIsSearching(false);
        setRecords([]);
      });
  }

  function handleSearch(search) {
    setTable((table) => ({
      ...table,
      currentPage: 1,
    }));
    setIsSearching(true);
    setSearch(search);
  }

  function handleSetItemsPerPage(limit) {
    setTable((table) => ({
      ...table,
      itemsPerPage: limit,
      currentPage: 1,
    }));
  }

  function handleSetPage(page) {
    setTable((table) => ({
      ...table,
      currentPage: page,
      selectedRecord: {},
      selectedRows: [],
    }));
  }

  function handleSetSelectedRows(rows) {
    if (rows) {
      var selectedRecord = {};
      if (rows.length > 0) {
        selectedRecord = records.filter((record) => {
          return record.id === rows[0].uid;
        })[0];
      }
      if (window.REACT_APP_DEBUG) {
        console.log("handleSetSelectedRows:");
        console.log(selectedRecord);
      }

      setTable((table) => ({
        ...table,
        selectedRows: rows,
        selectedRecord: selectedRecord,
      }));
    } else {
      setTable((table) => ({
        ...table,
        selectedRows: [],
        selectedRecord: {},
      }));
    }
  }

  function handleSetSortedBy(field) {
    if (window.REACT_APP_DEBUG) console.log("handleSetSortedBy: " + field);
    setTable((table) => ({
      ...table,
      currentPage: 1,
      isTableDisabled: true,
      sortedBy: field,
    }));
  }

  function handleSetSortDescending(boolValue) {
    setTable((table) => ({
      ...table,
      currentPage: 1,
      isTableDisabled: true,
      sortDescending: boolValue,
    }));
  }

  async function handleExportButtonClick() {
    if (window.REACT_APP_DEBUG)
      console.log("Employee Comments: handleExportButtonClick");

    setIsExporting(true);

    const isExport = true;

    const fetchUrl = formatUrl(isExport);

    try {
      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.apiToken.accessToken}`,
        },
      });

      if (!response.ok) {
        throw response.status;
      }

      const data = await response.json();

      const formattedData = formatData(data);

      const dataToExport = formattedData.map(
        ({
          id,
          employeeFirstName,
          employeeLastName,
          separationDate,
          supervisorName,
          separationReasons,
          ...columnsToKeep
        }) => ({
          ...columnsToKeep,
        })
      );

      console.log("data to export", dataToExport);

      setExcelExportData(dataToExport);
      setIsExporting(false);
      setCommentsExportModalShow(true);
    } catch (error) {
      console.error(error);
    }
  }

  // get the selected records into an array
  var selectedRecords = [];
  for (const row of table.selectedRows) {
    let rowToAdd = records.filter(
      (record) => record[table.uidProperty] === row.uid
    )[0];
    if (rowToAdd) {
      selectedRecords.push(rowToAdd);
    }
  }

  var resultsCountString = "";
  if (table.totalItems > 0) {
    resultsCountString =
      table.currentPage * table.itemsPerPage -
      (table.itemsPerPage - 1) +
      "-" +
      (table.currentPage * table.itemsPerPage -
        table.itemsPerPage +
        records.length) +
      " of " +
      table.totalItems;
  } else {
    resultsCountString = "0 items";
  }

  return (
    <div className="container-fluid">
      <div className="row no-gutters">
        <div className="col mt-4 col-md-3 mb-2">
          <h4>Employee Comments</h4>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body pb-1">
              <div className="row">
                <div className="col col-12 col-md-6">
                  <div className="row align-items-end">
                    <div className="col align-items-end">
                      <div className="mb-3">
                        <Search
                          isSearching={isSearching}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="col align-items-end">
                      <DateInput
                        label="From"
                        onChange={(date) =>
                          setFromSeparationDate(date === "" ? null : date)
                        }
                      />
                    </div>
                    <div className="col align-items-end">
                      <DateInput
                        label="To"
                        onChange={(date) =>
                          setToSeparationDate(date === "" ? null : date)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-md-6 text-right pr-2 d-flex align-items-end justify-content-end">
                  <div className="mb-3">
                    <TableToolbar
                      deleteMultiple={false}
                      disabled={
                        fetchResponseStatusCode > 399 ||
                        isExporting ||
                        table.isTableDisabled ||
                        (fetchResponseStatusCode > 399 &&
                          fetchResponseStatusCode !== 404)
                      }
                      editMultiple={false}
                      selectedRecords={selectedRecords}
                      showAddButton={false}
                      showDeleteButton={false}
                      showEditButton={false}
                      showExportButton={true}
                      exportSelectedOnly={false}
                      handleExport={handleExportButtonClick}
                    />
                  </div>
                </div>
              </div>
              <div className="row no-gutters pt-3">
                <div
                  className="col overflow-auto"
                  style={{ height: `calc(100vh - 373px)` }}
                >
                  <Table
                    checkboxSelectOnly={false}
                    columns={tableColumns}
                    disabled={isExporting || table.isTableDisabled}
                    handleSetSelectedRows={handleSetSelectedRows}
                    handleSetSortedBy={handleSetSortedBy}
                    handleSetSortDescending={handleSetSortDescending}
                    isBordered={false}
                    isLoading={isLoading}
                    isMultiSelect={false}
                    isSmall={true}
                    records={records}
                    responseStatusCode={fetchResponseStatusCode}
                    responseStatusMessage={fetchResponseStatusMessage}
                    responseStatusVariant={fetchResponseStatusVariant}
                    selectedRows={table.selectedRows}
                    sortDescending={table.sortDescending}
                    sortedBy={table.sortedBy}
                    uidProperty={table.uidProperty}
                    useHoverableRows={true}
                    useStripedRows={false}
                  />
                </div>
              </div>
              <hr className="my-0 py-0" />
              <div className="row no-gutters">
                <div className="col mt-1">
                  <ItemsPerPage
                    disabled={
                      table.isTableDisabled ||
                      (fetchResponseStatusCode > 399 &&
                        fetchResponseStatusCode !== 404)
                    }
                    onChange={handleSetItemsPerPage}
                    options={table.itemsPerPageOptions}
                    value={table.itemsPerPage}
                  />
                </div>
                <div className="col align-middle">
                  <div>
                    <center>
                      <div className="mt-2 text-muted">
                        {resultsCountString}
                      </div>
                    </center>
                  </div>
                </div>
                <div className="col d-flex justify-content-end py-1">
                  <Pagination
                    currentPage={table.currentPage}
                    disabled={
                      table.isTableDisabled ||
                      (fetchResponseStatusCode > 399 &&
                        fetchResponseStatusCode !== 404)
                    }
                    onChange={handleSetPage}
                    totalPages={Math.ceil(
                      table.totalItems / table.itemsPerPage
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {commentsExportModalShow && (
        <EmployeeCommentsExportModal
          onHide={() => setCommentsExportModalShow(false)}
          show={commentsExportModalShow}
          records={excelExportData}
        />
      )}
    </div>
  );
}

let tableColumns = [
  {
    name: "PR #",
    property: "prNumber",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Employee",
    property: "employeeNameFormatted",
    sortable: true,
    sortableProperty: "employeeLastName",
    nowrap: true,
  },
  {
    name: "Employee ID",
    property: "employeePersonId",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Separation Reasons",
    property: "separationReasonsFormatted",
    sortable: false,
  },
  {
    name: "Last Day Title",
    property: "lastDayTitle",
    sortable: true,
  },
  {
    name: "Separation Date",
    property: "separationDateFormatted",
    sortableProperty: "separationDate",
    sortable: true,
    align: "center",
    nowrap: true,
  },
  {
    name: "Department Name",
    property: "deptName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Department #",
    property: "orgUnit",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Supervisor",
    property: "supervisorNameFormatted",
    sortableProperty: "supervisorName",
    sortable: true,
    nowrap: true,
  },
  {
    name: "Explanation for Separation",
    property: "separationReasonExplanation",
    sortable: true,
    width: 500,
  },
  {
    name: "Employee Comments",
    property: "separationSectionDEmployeeComments",
    sortable: true,
    width: 500,
  },
];
