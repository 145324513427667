import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
// @ukhc/devops-react-library
import Button from "@ukhc/devops-react-library/components/Button";
import TextArea from "@ukhc/devops-react-library/components/TextArea";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";

import { encodeBase64 } from "../utils/Helpers";

export default function CancelSeparationModal({ ...props }) {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [httpResponseStatusCode, setHttpResponseStatusCode] = useState(null);

  function handleCloseModal() {
    // Close the modal
    document.getElementById("closeCancelSeparationModalButton").click();
    props.onHide();
  }

  function handleSubmit(e) {
    setIsSubmitButtonDisabled(true);

    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    let fetchUrl = window.REACT_APP_API_URL + "/records/" + props.record.id;

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify([
        {
          op: "replace",
          path: "/canceled",
          value: true,
        },
        {
          op: "replace",
          path: "/canceledComments",
          value: encodeBase64(e.target.canceledComments.value),
        },
      ]),
    })
      .then((response) => {
        if (response.ok) {
          if (window.REACT_APP_DEBUG)
            console.log(
              "CancelSeparationModal handleSubmit response: " + response
            );
          return response.status;
        } else {
          setHttpResponseStatusCode(response.status);
          throw new Error(response.status);
        }
      })
      .then((result) => {
        setIsSubmitButtonDisabled(false);
        return true;
      })
      .then(handleCloseModal)
      .catch((error) => {
        if (window.REACT_APP_DEBUG)
          console.log(
            "CancelSeparationModal handleSubmit error: " + JSON.stringify(error)
          );
        return false;
      });
  }

  function handleConfirmation(event) {
    console.log("handleConfirmation:", event.target.checked);
    if (event.target.checked) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }

  function handleTextAreaOnChange() {
    setIsSubmitButtonDisabled(false);
  }

  return (
    <div
      className="modal fade"
      id="cancelSeparationModal"
      role="dialog"
      aria-labelledby="cancelSeparationModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="cancelSeparationModalLongTitle">
              <i className="bi bi-x-circle text-primary mr-2"></i>
              Cancel Separation (PR: #{props.record.prNumber})
            </h5>
            <button
              type="button"
              id="closeCancelSeparationModalButton"
              className="close"
              onClick={handleCloseModal}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h6 className="mb-3">
              Cancel the current separation request for{" "}
              <strong>
                {props.record.employeeFirstName} {props.record.employeeLastName}
              </strong>
              .
            </h6>
            <form
              className="needs-validation"
              noValidate
              id="employeeCancelConfirmationForm"
              onSubmit={handleSubmit}
            >
              <div className="form-check d-flex mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="employeeCancelConfirmation"
                  onChange={handleConfirmation}
                  required={true}
                />
                <label
                  className="form-check-label d-flex"
                  htmlFor="employeeCancelConfirmation"
                >
                  <span style={{ fontSize: 13, letterSpacing: 0.25 }}>
                    Cancel employee separation request.
                  </span>
                </label>
              </div>
              <TextArea
                required={true}
                id="canceledComments"
                label="Comments"
                onChange={handleTextAreaOnChange}
                rows={3}
                invalidFeedback="Please provide a reason for cancelling employee separation."
              />
              <div className="modal-footer pb-0">
                <Button
                  variant="secondary"
                  isOutline={true}
                  text="Close"
                  onClick={handleCloseModal}
                />
                <Button
                  type="submit"
                  variant="danger"
                  id="employeeCancelButton"
                  isDisabled={isSubmitButtonDisabled}
                  text="Cancel Separation"
                />
              </div>
            </form>
            {httpResponseStatusCode > 399 && (
              <ResponseStatusAlert statusCode={httpResponseStatusCode} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
