import React, { Component } from "react";
// @ukhc/devops-react-libary
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";
// App components
import { PrimaryReasons, SecondaryReasons } from "./Reasons";

import { encodeBase64 } from "../utils/Helpers";

class SignFormModal extends Component {
  constructor(props) {
    super(props);

    // Set up primary reason select options
    const primaryReasonOptions = [
      { value: "", label: "Select a primary reason" },
    ];

    PrimaryReasons.forEach((reason) => {
      primaryReasonOptions.push({
        label: reason.label,
        value: reason.label,
      });
    });

    // Filter the secondary reason options by the selected primary reason
    const secondaryReasonOptions = this.getSecondaryReasons(
      props.record.primaryReasonValue
    );

    this.state = {
      fetchResponseStatusCode: null,
      primaryReasonOptions: primaryReasonOptions,
      primaryReasonValue: props.record.primaryReasonValue,
      secondaryReasonOptions: secondaryReasonOptions,
      secondaryReasonValue: props.record.secondaryReasonValue,
      reasonsModified: false,
      separationReasonsTextLength: 0,
      employeeCommentsTextLength: 0,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePrimaryReasonChange = this.handlePrimaryReasonChange.bind(this);
    this.handleSecondaryReasonChange =
      this.handleSecondaryReasonChange.bind(this);
    this.handleAddSeparationReasons =
      this.handleAddSeparationReasons.bind(this);
    this.handleDeleteSeparationReasons =
      this.handleDeleteSeparationReasons.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.getSecondaryReasons = this.getSecondaryReasons.bind(this);
  }

  getSecondaryReasons(primaryReasonValue) {
    const secondaryReasonOptions = [
      { value: "", label: "Select a secondary reason" },
    ];

    var filteredSecondaryReasons = [];
    if (primaryReasonValue) {
      var primaryReason = primaryReasonValue;
      // Filter the secondary reasons
      filteredSecondaryReasons = SecondaryReasons.filter((secondaryReason) => {
        return primaryReason === secondaryReason.primaryReason;
      });
    }

    filteredSecondaryReasons.forEach((reason) => {
      secondaryReasonOptions.push({ value: reason.label, label: reason.label });
    });

    return secondaryReasonOptions;
  }

  handleOnChange(prop, event) {
    switch (prop) {
      case "separationSectionDEmployeeComments":
        this.setState({ employeeCommentsTextLength: event.length });
        break;
      case "separationReasonExplanation":
        this.setState({ separationReasonsTextLength: event.length });
        break;
      case "primaryReasonValue":
        this.handlePrimaryReasonChange(event);
        break;
      case "secondaryReasonValue":
        this.handleSecondaryReasonChange(event);
        break;
      default:
        break;
    }
  }

  handlePrimaryReasonChange(newReason) {
    this.setState({
      primaryReasonValue: newReason,
      secondaryReasonOptions: this.getSecondaryReasons(newReason),
      reasonsModified: true,
    });
  }

  handleSecondaryReasonChange(newReason) {
    this.setState({
      secondaryReasonValue: newReason,
      reasonsModified: true,
    });
  }

  handleAddSeparationReasons(reasonTitles) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleAddSeparationReasons reasonTitles:", reasonTitles);
    }

    reasonTitles.forEach(async (title) => {
      const fetchUrl = window.REACT_APP_API_URL + "/separationReasons";

      const postObject = {
        recordId: this.props.record.id,
        separationReasonText: title,
      };

      try {
        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
          },
          body: JSON.stringify(postObject),
        });
        const json = await response.json();
        if (window.REACT_APP_DEBUG)
          console.log("handleAddSeparationReasons reason added:", json);
      } catch (error) {
        // TODO: Error handling
        if (window.REACT_APP_DEBUG) console.log(error);
      }
    });
  }

  handleDeleteSeparationReasons(reasonIds) {
    if (window.REACT_APP_DEBUG) {
      console.log("handleDeleteSeparationReasons reasonIds:", reasonIds);
    }

    reasonIds.forEach((id) => {
      const fetchUrl = window.REACT_APP_API_URL + "/separationReasons/" + id;

      fetch(fetchUrl, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
        },
      })
        .then((response) => response.status)
        .then(
          (result) => {
            return true;
          },
          (error) => {
            console.error("Error during delete: " + error);
            return false;
          }
        );
    });
  }

  handleCloseModal() {
    // Reset error message
    this.setState({
      fetchResponseStatusCode: null,
    });

    // Close modal
    document.getElementById("closeSignFormModalButton").click();
    this.props.onHide();
  }

  handleOnSubmit(record) {
    if (this.state.reasonsModified) {
      // Collect current ids in separationReasons array
      const idsToDeleteArray = [];
      this.props.record.separationReasons.forEach((reason) => {
        idsToDeleteArray.push(reason.id);
      });

      const reasonsToAddArray = [
        this.state.primaryReasonValue,
        this.state.secondaryReasonValue,
      ];

      if (reasonsToAddArray.length > 0) {
        this.handleAddSeparationReasons(reasonsToAddArray);
      }

      if (idsToDeleteArray.length > 0) {
        this.handleDeleteSeparationReasons(idsToDeleteArray);
      }
    }

    if (window.REACT_APP_DEBUG) {
      console.log("SignFormModal handleOnSubmit record", record);
    }

    const user = this.props.user;

    record.preventDefault();

    const form = record.currentTarget;
    if (form.checkValidity() === false) {
      record.preventDefault();
      record.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    let moment = require("moment");

    var patchJson = [];

    // If phase is employee and user is employee, patch employee
    if (
      this.props.record.signingPhase === "employee" &&
      user.isUser &&
      user.linkblue === this.props.record.employeeLinkBlueId
    ) {
      patchJson.push(
        {
          op: "replace",
          path: "/employeeSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/employeeSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
      );

      // Employee Comments is updated
      if (
        String(record.target.separationSectionDEmployeeComments.value) !==
        String(this.props.record.separationSectionDEmployeeComments)
      ) {
        if (window.REACT_APP_DEBUG)
          console.log(
            "target: " + record.target.separationSectionDEmployeeComments.value
          );
        if (window.REACT_APP_DEBUG)
          console.log(
            "prop: " + this.props.record.separationSectionDEmployeeComments
          );
        patchJson.push({
          op: "replace",
          path: "/separationSectionDEmployeeComments",
          value: encodeBase64(
            record.target.separationSectionDEmployeeComments.value
          ),
        });
      }
    }

    // If phase is admin and user is admin, patch admin
    if (
      this.props.record.signingPhase === "authorizedIndividual" &&
      user.linkblue === this.props.record.authorizedIndividualLinkBlueId
    ) {
      patchJson.push(
        {
          op: "replace",
          path: "/authorizedIndividualSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/authorizedIndividualSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
      );
    }

    if (
      this.props.record.signingPhase === "supervisor" &&
      (user.isSupervisor || user.isProxy)
    ) {
      let displayName = this.props.user.displayName;
      let formattedDisplayName = displayName.split(",");
      patchJson.push(
        {
          op: "replace",
          path: "/supervisorSigned",
          value: true,
        },
        {
          op: "replace",
          path: "/supervisorSignatureDate",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          op: "replace",
          path: "/supervisorProxySignature",
          value: encodeBase64(
            `${formattedDisplayName[1].trim()} ${formattedDisplayName[0].trim()}`
          ),
        }
      );
    }

    // If phase is supervisor and user is supervisor, patch supervisor
    if (
      (this.props.record.signingPhase === "supervisor" &&
        (user.isSupervisor || user.isProxy)) ||
      (this.props.record.signingPhase === "authorizedIndividual" &&
        user.linkblue === this.props.record.authorizedIndividualLinkBlueId &&
        this.props.record.supervisorSignatureNotDesignated)
    ) {
      // // First name is updated
      // if (
      //   String(record.target.employeeFirstName.value) !==
      //   String(this.props.record.employeeFirstName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/employeeFirstName",
      //     value: encodeBase64(record.target.employeeFirstName.value),
      //   });
      // }

      // // Last name is updated
      // if (
      //   String(record.target.employeeLastName.value) !==
      //   String(this.props.record.employeeLastName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/employeeLastName",
      //     value: encodeBase64(record.target.employeeLastName.value),
      //   });
      // }

      // // Forwarding Address is updated
      // if (
      //   String(record.target.forwardingAddress.value) !==
      //   String(this.props.record.forwardingAddress)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/forwardingAddress",
      //     value: encodeBase64(record.target.forwardingAddress.value),
      //   });
      // }

      // // Org Unit is updated
      // if (
      //   String(record.target.orgUnit.value) !==
      //   String(this.props.record.orgUnit)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/orgUnit",
      //     value: encodeBase64(record.target.orgUnit.value),
      //   });
      // }

      // // Dept Name is updated
      // if (
      //   String(record.target.deptName.value) !==
      //   String(this.props.record.deptName)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/deptName",
      //     value: encodeBase64(record.target.deptName.value),
      //   });
      // }

      // // Position # is updated
      // if (
      //   String(record.target.positionCode.value) !==
      //   String(this.props.record.positionCode)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/positionCode",
      //     value: encodeBase64(record.target.positionCode.value),
      //   });
      // }

      // // Division is updated
      // if (
      //   String(record.target.division.value) !==
      //   String(this.props.record.division)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/division",
      //     value: encodeBase64(record.target.division.value),
      //   });
      // }

      // // First Day Worked is updated
      // const formattedFirstDateWorked =
      //   this.props.record.firstDateWorked === null
      //     ? ""
      //     : moment(this.props.record.firstDateWorked).format("YYYY-MM-DD");
      // if (record.target.firstDateWorked.value !== formattedFirstDateWorked) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/firstDateWorked",
      //     value: record.target.firstDateWorked.value,
      //   });
      // }

      // // First Day Title is updated
      // if (
      //   String(record.target.separationFirstDayTitle.value) !==
      //   String(this.props.record.separationFirstDayTitle)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationFirstDayTitle",
      //     value: encodeBase64(record.target.separationFirstDayTitle.value),
      //   });
      // }

      // // First Day Status is updated
      // if (
      //   String(record.target.separationFirstDayStatusTempRegular.value) !==
      //   String(this.props.record.separationFirstDayStatusTempRegular)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationFirstDayStatusTempRegular",
      //     value: encodeBase64(
      //       record.target.separationFirstDayStatusTempRegular.value
      //     ),
      //   });
      // }

      // // First Day PT/FT is updated
      // if (
      //   String(record.target.firstDayStatusPTFT.value) !==
      //   String(this.props.record.firstDayStatusPTFT)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/firstDayStatusPTFT",
      //     value: encodeBase64(record.target.firstDayStatusPTFT.value),
      //   });
      // }

      // // Last Day Worked is updated
      // const formattedLastDateWorked =
      //   this.props.record.lastDateWorked === null
      //     ? ""
      //     : moment(this.props.record.lastDateWorked).format("YYYY-MM-DD");
      // if (record.target.lastDateWorked.value !== formattedLastDateWorked) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDateWorked",
      //     value: record.target.lastDateWorked.value,
      //   });
      // }

      // // Last Day Title is updated
      // if (
      //   String(record.target.lastDayTitle.value) !==
      //   String(this.props.record.lastDayTitle)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDayTitle",
      //     value: encodeBase64(record.target.lastDayTitle.value),
      //   });
      // }

      // // Last Day PT/FT is updated
      // if (
      //   String(record.target.lastDayStatusPTFT.value) !==
      //   String(this.props.record.lastDayStatusPTFT)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/lastDayStatusPTFT",
      //     value: encodeBase64(record.target.lastDayStatusPTFT.value),
      //   });
      // }

      // // Date Notice Given is updated
      // const formattedDateNoticeGiven =
      //   this.props.record.dateNoticeGiven === null
      //     ? ""
      //     : moment(this.props.record.dateNoticeGiven).format("YYYY-MM-DD");
      // if (record.target.dateNoticeGiven.value !== formattedDateNoticeGiven) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/dateNoticeGiven",
      //     value: record.target.dateNoticeGiven.value,
      //   });
      // }

      // // Notice Given Via is updated
      // if (
      //   String(record.target.separationNoticeGivenVia.value) !==
      //   String(this.props.record.separationNoticeGivenVia)
      // ) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationNoticeGivenVia",
      //     value: encodeBase64(record.target.separationNoticeGivenVia.value),
      //   });
      // }

      // // Separation Date is updated
      // const formattedSeparationDate =
      //   this.props.record.separationDate === null
      //     ? ""
      //     : moment(this.props.record.separationDate).format("YYYY-MM-DD");
      // if (record.target.separationDate.value !== formattedSeparationDate) {
      //   patchJson.push({
      //     op: "replace",
      //     path: "/separationDate",
      //     value: record.target.separationDate.value,
      //   });
      // }

      // Separation Reason is updated
      if (
        String(record.target.separationReasonExplanation.value) !==
        String(this.props.record.separationReasonExplanation)
      ) {
        patchJson.push({
          op: "replace",
          path: "/separationReasonExplanation",
          value: encodeBase64(record.target.separationReasonExplanation.value),
        });
      }
    }

    let fetchUrl =
      window.REACT_APP_API_URL + "/records/" + this.props.record.id;

    if (window.REACT_APP_DEBUG) console.log("handleSubmitForm: " + fetchUrl);

    fetch(fetchUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.apiToken.accessToken}`,
      },
      body: JSON.stringify(patchJson),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        this.setState({ fetchResponseStatusCode: response.status });
        return;
      })
      .then(() => {
        this.handleCloseModal();
      })

      .catch((error) => {
        if (window.REACT_APP_DEBUG) {
          console.log("Records error: " + error);
          console.dir(error);
        }

        if (error.message === "500") {
          this.setState({ fetchResponseStatusCode: 500 });
        }
      });
  }

  render() {
    let formControls = {
      // TODO: Update this conditionally based on role/phase
      formDescription:
        "If any of the information is incorrect, or has changed since it was initiated, please DO NOT SIGN. Contact your supervisor.",
      rows: [
        {
          cols: [
            {
              label: "Employee/Position Information",
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "First Name",
                  property: "employeeFirstName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Name",
                  property: "employeeLastName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Person ID",
                  property: "employeePersonId",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Forwarding Address",
                  property: "forwardingAddress",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Org. Unit",
                  property: "orgUnit",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Dept. Name",
                  property: "deptName",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Position #",
                  property: "positionCode",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Division",
                  property: "division",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "First Day Worked",
                  property: "firstDateWorked",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Title",
                  property: "separationFirstDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day Status",
                  property: "separationFirstDayStatusTempRegular",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Temporary", label: "Temporary" },
                    { value: "Regular", label: "Regular" },
                  ],
                },
              ],
            },
            {
              controls: [
                {
                  label: "First Day PT/FT",
                  property: "firstDayStatusPTFT",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Part Time", label: "Part Time" },
                    { value: "Full Time", label: "Full Time" },
                  ],
                },
              ],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Last Day Worked",
                  property: "lastDateWorked",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Title",
                  property: "lastDayTitle",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Last Day Status",
                  property: "lastDayStatusPTFT",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Reg FT", label: "Reg FT" }, // Regular | Full Time
                    { value: "Temp PT >.20", label: "Temp PT >.20" }, // Temp | Part Time
                    { value: "Temp PT <.20", label: "Temp PT <.20" }, // Temp | Part Time
                    { value: "Temp FT", label: "Temp FT" }, // Temp | Full Time
                    { value: "Non-Service", label: "Non-Service" }, // n/a | n/a
                    { value: "Phased Retiree", label: "Phased Retiree" }, // Regular | Part Time
                    { value: "Reg PT >.74", label: "Reg PT >.74" }, // Regular | Part Time
                    { value: "Reg PT-Faculty", label: "Reg PT-Faculty" }, // Regular | Part Time
                    { value: "Unpaid", label: "Unpaid" }, // n/a | n/a
                    { value: "Reg PT .50-.74", label: "Reg PT .50-.74" }, // Regular | Part Time
                    { value: "On Call", label: "On Call" }, // Temp | Part Time
                    { value: "Prem On Call", label: "Prem On Call" }, // Temp | Part Time
                    { value: "WEPP", label: "WEPP" }, // Regular | Part Time
                    { value: "Reg PT .20-.49", label: "Reg PT .20-.49" }, // Regular | Part Time
                  ],
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              controls: [
                {
                  label: "Notice Given",
                  property: "dateNoticeGiven",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Notice Type",
                  property: "separationNoticeGivenVia",
                  disabled: true,
                  type: "select",
                  options: [
                    { value: "", label: "--Select one--" },
                    { value: "Written", label: "Written" },
                    { value: "Oral", label: "Oral" },
                    { value: "None", label: "None" },
                  ],
                },
              ],
            },
            {
              controls: [
                {
                  label: "Separation Date",
                  property: "separationDate",
                  disabled: true,
                  type: "date",
                },
              ],
            },
            {
              controls: [],
            },
          ],
        },
        {
          cols: [
            {
              label: "Reasons for Separation",
            },
          ],
        },
      ],
    };

    if (
      (this.props.record.signingPhase === "supervisor" &&
        (this.props.user.isSupervisor || this.props.user.isProxy)) ||
      this.props.record.authorizedIndividualCanEdit
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                type: "select",
                label: "Primary Reason",
                options: this.state.primaryReasonOptions,
                property: "primaryReasonValue",
                // required: true,
                disabled: true,
              },
            ],
          },
          {
            controls: [
              {
                type: "select",
                label: "Secondary Reason",
                options: this.state.secondaryReasonOptions,
                property: "secondaryReasonValue",
                disabled: true,
                // required: true,
              },
            ],
          },
        ],
      });
    } else {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Separation Reasons",
                property: "separationReasonsFormatted",
                disabled: true,
                type: "textarea",
              },
            ],
          },
        ],
      });
    }

    let separationExplanationEnabled =
      (this.props.record.signingPhase === "supervisor" &&
        (this.props.user.isSupervisor || this.props.user.isProxy)) ||
      this.props.record.authorizedIndividualCanEdit;

    let employeeCommentsEnabled =
      this.props.record.signingPhase === "employee" &&
      this.props.user.isUser &&
      this.props.user.linkblue === this.props.record.employeeLinkBlueId;

    formControls.rows.push(
      {
        cols: [
          {
            controls: [
              {
                label: "Explanation for Separation",
                property: "separationReasonExplanation",
                disabled: !separationExplanationEnabled,
                type: "textarea",
                required:
                  this.state.secondaryReasonValue ===
                  "Other (Must Explain/Unknown)",
                maxLength: 450,
                invalidFeedback: "Comments must be less than 450 characters.",
                helpText: separationExplanationEnabled
                  ? `${
                      this.state.separationReasonsTextLength > 450
                        ? 0
                        : 450 - this.state.separationReasonsTextLength
                    } character${
                      450 - this.state.separationReasonsTextLength !== 1
                        ? "s"
                        : ""
                    } remaining.`
                  : "",
                showHelpButton: !separationExplanationEnabled,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Employee's Comments",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "Employee's Comments",
                property: "separationSectionDEmployeeComments",
                disabled: !employeeCommentsEnabled,
                type: "textarea",
                maxLength: 450,
                invalidFeedback: "Comments must be less than 450 characters.",
                helpText: employeeCommentsEnabled
                  ? `${
                      this.state.employeeCommentsTextLength > 450
                        ? 0
                        : 450 - this.state.employeeCommentsTextLength
                    } character${
                      450 - this.state.employeeCommentsTextLength !== 1
                        ? "s"
                        : ""
                    } remaining.`
                  : "",
                showHelpButton: !employeeCommentsEnabled,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Signatures",
            description: "Enter full name below and click Sign Form.",
          },
        ],
      }
    );

    if (
      this.props.record.signingPhase === "supervisor" &&
      (this.props.user.isSupervisor || this.props.user.isProxy)
    ) {
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Supervisor/Proxy",
                property: "supervisorProxySignature",
                disabled: false,
                required: true,
                type: "text",
              },
            ],
          },
          {
            controls: [],
          },
        ],
      });
    }

    if (
      this.props.record.signingPhase === "authorizedIndividual" &&
      this.props.user.linkblue.toLowerCase() ===
        this.props.record.authorizedIndividualLinkBlueId.toLowerCase()
    ) {
      if (!this.props.record.supervisorSignatureNotDesignated) {
        formControls.rows.push({
          cols: [
            {
              controls: [
                {
                  label: "Supervisor",
                  property: "supervisorNameFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Supervisor Signature Date",
                  property: "supervisorSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        });
      }
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Individual Authorized to Remove Employee From Payroll",
                property: "authorizedIndividualSignature",
                disabled: false,
                required: true,
                type: "text",
              },
            ],
          },
          {
            controls: [],
          },
        ],
      });
    }

    if (
      this.props.record.signingPhase === "employee" &&
      this.props.user.isUser &&
      this.props.user.linkblue === this.props.record.employeeLinkBlueId
    ) {
      if (!this.props.record.supervisorSignatureNotDesignated) {
        formControls.rows.push({
          cols: [
            {
              controls: [
                {
                  label: "Supervisor",
                  property: "supervisorNameFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label: "Supervisor Signature Date",
                  property: "supervisorSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        });
      }

      if (!this.props.record.authorizedIndividualSignatureNotDesignated) {
        formControls.rows.push({
          cols: [
            {
              controls: [
                {
                  label:
                    "Individual Authorized to Remove Employee From Payroll",
                  property: "authorizedIndividualNameFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
            {
              controls: [
                {
                  label:
                    "Individual Authorized to Remove Employee From Payroll Signature Date",
                  property: "authorizedIndividualSignatureDateFormatted",
                  disabled: true,
                  type: "text",
                },
              ],
            },
          ],
        });
      }
      formControls.rows.push({
        cols: [
          {
            controls: [
              {
                label: "Employee",
                property: "employeeSignature",
                disabled: false,
                required: true,
                type: "text",
              },
            ],
          },
          {
            controls: [],
          },
        ],
      });
    }

    return (
      <div
        className="modal fade"
        id="signFormModal"
        role="dialog"
        aria-labelledby="signFormModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title font-weight-bold"
                id="signFormModalLongTitle"
              >
                <i className="bi bi-pen text-primary mr-2"></i> Sign form for{" "}
                {this.props.record.employeeFirstName}{" "}
                {this.props.record.employeeLastName}
              </h5>
              <button
                type="button"
                id="closeSignFormModalButton"
                className="close"
                onClick={this.handleCloseModal}
                data-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onCancel={this.handleCloseModal}
                onChange={this.handleOnChange}
                onSubmit={this.handleOnSubmit}
                controls={formControls}
                record={this.props.record}
                submitButtonLabel="Sign Form"
              />
              {this.state.fetchResponseStatusCode > 399 && (
                <ResponseStatusAlert
                  statusCode={this.state.fetchResponseStatusCode}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignFormModal;
